import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "../services/configuration.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class RequestInterceptorService implements HttpInterceptor {
  private jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private readonly config: ConfigurationService,
    private router: Router
  ) {}

  public intercept(
    req: HttpRequest<object>,
    next: HttpHandler
  ): Observable<HttpEvent<object>> {
    const accessToken = this.config.fetchToken("accessToken");
    if (accessToken) {
      if (this.jwtHelper.isTokenExpired(accessToken)) {
        this.config.clearToken();
        this.router.navigateByUrl("/login");
      }
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return next.handle(req);
  }
}
