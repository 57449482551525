/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfileCompetencyDTO } from './profileCompetencyDTO';
import { ProfileProjectDTO } from './profileProjectDTO';
import { ExternalProjectDTO } from './externalProjectDTO';
import { ProfileEducationDTO } from './profileEducationDTO';


export interface ProfileDTO { 
    id?: number;
    employeeId: number;
    employeeName: string;
    employeeEmail: string;
    seniority?: ProfileDTO.SeniorityEnum;
    showSeniority?: boolean;
    employeePosition?: string;
    done: boolean;
    internalProjects?: Array<ProfileProjectDTO>;
    externalProjects?: Array<ExternalProjectDTO>;
    competencies?: Array<ProfileCompetencyDTO>;
    education?: Array<ProfileEducationDTO>;
}
export namespace ProfileDTO {
    export type SeniorityEnum = 'JUNIOR' | 'JUNIOR_MEDIOR' | 'MEDIOR' | 'MEDIOR_SENIOR' | 'SENIOR';
    export const SeniorityEnum = {
        Junior: 'JUNIOR' as SeniorityEnum,
        JuniorMedior: 'JUNIOR_MEDIOR' as SeniorityEnum,
        Medior: 'MEDIOR' as SeniorityEnum,
        MediorSenior: 'MEDIOR_SENIOR' as SeniorityEnum,
        Senior: 'SENIOR' as SeniorityEnum
    };
}


