<div class="main-container h-100 mh-100">
  <div *ngIf="!profiles.length" class="image-container">
    <label class="drag-here">{{
      "profile_export.export_queue_table.drag_here" | translate
    }}</label>
    <img src="..\..\..\assets\img\squirrel_queue.png" />
  </div>
  <div *ngIf="profiles.length" class="table-container">
    <table
      class="table table-light table-hover table-striped table-bordered align-middle scrollable-table w-100"
    >
      <thead>
        <tr>
          <th scope="col" sortable="name" class="table-header text-center name">
            {{ "profile_export.export_queue_table.name" | translate }}
          </th>
          <th
            scope="col"
            sortable="email"
            class="table-header text-center email"
          >
            {{ "profile_export.export_queue_table.email" | translate }}
          </th>
          <th scope="col" class="table-header text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          app-tr-profile-export-queue
          *ngFor="let profile of profiles"
          [profile]="profile"
          (profileRemovedFromQueue)="onProfileRemovedFromQueue($event)"
        ></tr>
      </tbody>
    </table>
  </div>
</div>
