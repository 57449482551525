import { CdkDragDrop, copyArrayItem } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Output } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  PdfExportDTO,
  PdfExportService,
  ProfileDTO,
} from "src/shared/api/generated";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-profile-export-queue",
  templateUrl: "./profile-export-queue.component.html",
  styleUrls: ["./profile-export-queue.component.scss"],
})
export class ProfileExportQueueComponent {
  @Output() profileExportQueueChanged = new EventEmitter<ProfileDTO[]>();
  profiles: ProfileDTO[] = [];
  anonymizeEmployees: boolean = false;
  useAlternateProjectName: boolean = false;
  displayProjectDescription: boolean = false;
  templateToUse: string = "gloster_pdf_template";
  contactEmail: string = "";

  constructor(
    private pdfExportService: PdfExportService,
    private spinnerService: NgxSpinnerService,
    private confirmationModalService: ConfirmationModalService
  ) {}

  drop(event: CdkDragDrop<ProfileDTO[]>) {
    if (this.profiles.find((p) => p.id === event.item.data.id)) {
      return;
    }
    copyArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    this.profileExportQueueChanged.emit(this.profiles);
  }

  onProfileRemovedFromQueue(remainingProfiles: ProfileDTO[]) {
    this.profiles = remainingProfiles;
    this.profileExportQueueChanged.emit(this.profiles);
  }

  isExportDisabled(): boolean {
    return this.contactEmail === "";
  }

  exportToPdf() {
    if (!this.profiles.length || this.isExportDisabled()) {
      return;
    }
    const pdfExportDto: PdfExportDTO = {
      profileIdList: this.profiles.map((p) => p.id!),
      anonymizeEmployees: this.anonymizeEmployees,
      useAlternateProjectName: this.useAlternateProjectName,
      displayProjectDescription: this.displayProjectDescription,
      templateToUse: this.templateToUse,
      contactEmail: this.contactEmail,
    };

    if (this.profiles.length > 1) {
      this.exportMultiple(pdfExportDto);
    } else {
      this.exportSingle(pdfExportDto);
    }
  }

  exportSingle(pdfExportDto: PdfExportDTO) {
    this.pdfExportService
      .exportProfileToPDF(pdfExportDto, "response")
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (response) => this.handleExportResponse(response, "profile.pdf"),
        error: (error) => this.handleExportError(error),
      });
  }

  exportMultiple(pdfExportDto: PdfExportDTO) {
    this.pdfExportService
      .exportProfilesToPDF(pdfExportDto, "response")
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (response) =>
          this.handleExportResponse(response, "profile_export.zip"),
        error: (error) => this.handleExportError(error),
      });
  }

  private handleExportResponse(response: any, defaultFileName: string) {
    const a = document.createElement("a");
    const objectUrl = URL.createObjectURL(response.body);
    a.href = objectUrl;
    a.download =
      this.getFileNameFromContentDisposition(
        response.headers.get("content-disposition")
      ) ?? defaultFileName;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  private handleExportError(error: any) {
    if (
      error.error.message === "Something went wrong while building PDF" ||
      error.error.message === "Something went wrong while building ZIP"
    ) {
      this.confirmationModalService.openConfirmationModal(
        "profile_export.export_queue_table.error.title",
        "profile_export.export_queue_table.error.employee_while_export",
        true
      );
    }
  }

  getFileNameFromContentDisposition(
    contentDisposition: string | null
  ): string | null {
    if (!contentDisposition) {
      return null;
    }
    const matches = /filename="([^"]*)"/.exec(contentDisposition);

    return matches != null && matches[1] ? matches[1] : null;
  }
}
