/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LatestImportStats { 
    importStatus?: LatestImportStats.ImportStatusEnum;
    fileName?: string;
    validRowsCount?: number;
    createdProjectsCount?: number;
    createdCompetenciesCount?: number;
    createdPlansCount?: number;
    runDateTime?: string;
}
export namespace LatestImportStats {
    export type ImportStatusEnum = 'SUCCESS' | 'FAILURE' | 'NOT_STARTED' | 'RUNNING';
    export const ImportStatusEnum = {
        Success: 'SUCCESS' as ImportStatusEnum,
        Failure: 'FAILURE' as ImportStatusEnum,
        NotStarted: 'NOT_STARTED' as ImportStatusEnum,
        Running: 'RUNNING' as ImportStatusEnum
    };
}


