import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  CompanyDTO,
  CompetencyDTO,
  CompetencyService,
  EmployeeDTO,
  EmployeeService,
  PositionDTO,
  PositionService,
} from "src/shared/api/generated";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/shared/directives/ngbd-sortable-header.directive";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { FormControl } from "@angular/forms";
import {
  Subscription,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  switchMap,
  tap,
} from "rxjs";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-table-employee",
  templateUrl: "./table-employee.component.html",
  styleUrls: ["./table-employee.component.scss"],
})
export class TableEmployeeComponent implements OnInit, OnDestroy {
  @Input() company: CompanyDTO;
  employees: EmployeeDTO[] = [];
  positions: PositionDTO[] = [];
  page = 1;
  pageSize = 15;
  collectionSize = 0;
  sorting: SortEvent = { column: "name", direction: "asc" };
  selectedRows: number[] = [];
  searchText = new FormControl("");
  search: boolean = false;
  competencies: Map<number, string> = new Map();

  timeoutId: any = null;

  createEmployeeMode: boolean = false;
  subscriptions: Subscription = new Subscription();

  constructor(
    private employeeService: EmployeeService,
    private competencyService: CompetencyService,
    private positionService: PositionService,
    private spinnerService: NgxSpinnerService,
    private competencyManagerService: CompetencyManagerService,
    private confirmationModalService: ConfirmationModalService
  ) {
    this.company = {} as CompanyDTO;
  }

  ngOnInit(): void {
    this.initialLoad();
    this.searchEmployees();
    this.subscriptions.add(
      this.competencyManagerService.competencyUpdated.subscribe((_) => {
        this.getAllCompetencies();
      })
    );
    this.subscriptions.add(
      this.competencyManagerService.competencyCreated.subscribe((_) => {
        this.getAllCompetencies();
      })
    );
    this.subscriptions.add(
      this.competencyManagerService.competencyDeleted.subscribe((_) => {
        forkJoin([
          this.getAllCompetenciesObservable(),
          this.getEmployeesForCurrentCompany(this.searchText.value ?? ""),
        ]).pipe(showLoadingSpinner(this.spinnerService)).subscribe(([competencies, employees]) => {
          competencies.forEach((competency: CompetencyDTO) => {
            this.competencies.set(competency.id!, competency.name);
          });
          this.selectedRows = [];
          this.employees = employees.content ?? [];
          this.collectionSize = employees.totalElements ?? 0;
        });
      })
    );
    this.subscriptions.add(
      this.competencyManagerService.employeeCompetencyUpdated.subscribe((_) => {
        this.refreshEmployees();
      })
    );
  }

  initialLoad() {
    forkJoin([
      this.getEmployeesForCurrentCompany(this.searchText.value ?? ""),
      this.getAllCompetenciesObservable(),
      this.getPositions(),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe(([employees, competencies, positions]) => {
        this.selectedRows = [];
        this.employees = employees.content ?? [];
        this.collectionSize = employees.totalElements ?? 0;
        competencies.forEach((competency: CompetencyDTO) => {
          this.competencies.set(competency.id!, competency.name);
        });
        this.positions = positions;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getEmployeesForCurrentCompany(searchTerm: string = "") {
    if (this.createEmployeeMode) {
      this.cancelCreateEmployee();
    }
    return this.employeeService.getPlannedEmployeesByCompanyId(
      this.company.id!,
      searchTerm.trim(),
      this.pageSize,
      this.page - 1,
      this.sorting.column,
      this.sorting.direction
    );
  }

  getAllCompetenciesObservable() {
    return this.competencyService.getAllCompetencies();
  }

  getAllCompetencies() {
    this.getAllCompetenciesObservable()
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((result) => {
        result.forEach((competency: CompetencyDTO) => {
          this.competencies.set(competency.id!, competency.name);
        });
      });
  }

  getPositions() {
    return this.positionService.getAllPositions();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> =
    new QueryList();

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.sorting = { column: column, direction: direction };
    this.refreshEmployees();
  }

  searchEmployees() {
    this.searchText.valueChanges
      .pipe(
        tap(() => (this.search = false)),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.competencyManagerService.clearEmployeeCompetency();
        }),
        switchMap((text) =>
          this.getEmployeesForCurrentCompany(text ?? "").pipe(
            showLoadingSpinner(this.spinnerService)
          )
        )
      )
      .subscribe((employeePage) => {
        this.selectedRows = [];
        this.employees = employeePage.content ?? [];
        this.collectionSize = employeePage.totalElements ?? 0;
        this.search = true;
      });
  }

  addEmptyEmployee() {
    this.employees.unshift({
      id: undefined,
      name: "",
      email: "",
      internal: true,
      active: true,
      planned: true,
      companyId: this.company.id!,
      competencies: {},
      // necessary because the generated DTO does not accept null,
      // but undefined causes a JSON parse error in certain cases
      // @ts-ignore
      positionId: null,
      averageOfTop3Competencies: 0,
    });
    this.collectionSize++;
    this.createEmployeeMode = true;
  }

  createEmployee() {
    this.employeeService
      .createEmployee(this.employees.find((employee) => !employee.id)!)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (createdEmployee) => {
          let index = this.employees.findIndex((employee) => !employee.id);
          this.employees[index] = { ...createdEmployee };
          this.createEmployeeMode = false;
        },
        error: (error) => {
          if (
            error.error.message ===
            "An employee with the same email address already exists"
          ) {
            this.confirmationModalService.openConfirmationModal(
              "employee.error.title",
              "employee.error.duplicate_email",
              true
            );
          }
        },
      });
  }

  cancelCreateEmployee() {
    this.collectionSize--;
    this.createEmployeeMode = false;
    this.employees = this.employees.filter((employee) => employee.id);
  }

  isCreateDisabled() {
    let createdEmployee = this.employees.find((employee) => !employee.id);
    return (
      createdEmployee?.name.trim() === "" ||
      createdEmployee?.email.trim() === ""
    );
  }

  refreshEmployees() {
    this.getEmployeesForCurrentCompany(this.searchText.value ?? "")
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((employeePage) => {
        this.selectedRows = [];
        this.employees = employeePage.content ?? [];
        this.collectionSize = employeePage.totalElements ?? 0;
      });
  }

  selectRow(id: number) {
    if (this.createEmployeeMode) {
      return;
    }
    if (this.selectedRows.includes(id)) {
      this.selectedRows = this.selectedRows.filter((rowId) => rowId !== id);
    } else {
      this.selectedRows.push(id);
    }
  }
}
