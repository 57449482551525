import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import {
  CompetencyGroupDTO,
  CompetencyGroupService,
  CompetencyService,
  CreateOrUpdateCompetencyDTO,
} from "src/shared/api/generated";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";

@Component({
  selector: "app-add-or-edit-competency-modal",
  templateUrl: "./add-or-edit-competency-modal.component.html",
  styleUrls: ["./add-or-edit-competency-modal.component.scss"],
})
export class AddOrEditCompetencyModalComponent {
  @Input() competency!: CreateOrUpdateCompetencyDTO;
  @Input() competencyGroups: CompetencyGroupDTO[] = [];
  @Input() createMode: boolean = false;
  error: boolean = false;
  errorMessage: string = "";

  constructor(
    public activeModal: NgbActiveModal,
    private competencyService: CompetencyService,
    private translate: TranslateService,
    private competencyGroupService: CompetencyGroupService,
    private competencyManagerService: CompetencyManagerService
  ) {}

  saveCompetency() {
    const competencyObservable = this.createMode
      ? this.competencyService.createCompetency(this.competency)
      : this.competencyService.updateCompetency(this.competency);

    competencyObservable.subscribe({
      next: (competency) => {
        this.competencyManagerService.setCompetencyCreated();
        this.activeModal.close(competency);
      },
      error: (error) => {
        this.openAlert(error.error.message);
      } 
    });
  }

  openAlert(error: string) {
    if(error === "Competency already exists with the name"){
      this.error = true;
      this.errorMessage = this.translate.instant(
        "competency.competency_add_modal.already_exists"
      );
    }
  }

  closeAlert() {
    this.error = false;
    this.errorMessage = "";
  }

  isSaveDisabled() {
    return this.competency.name === "";
  }
}
