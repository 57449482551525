/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlanMemberDTO } from './planMemberDTO';
import { ProjectCompetencyDTO } from './projectCompetencyDTO';


export interface LookingForResourceDTO { 
    id: number;
    projectId: number;
    projectName: string;
    projectState?: LookingForResourceDTO.ProjectStateEnum;
    fte: number;
    assigneeId: number;
    status: LookingForResourceDTO.StatusEnum;
    projectStart?: string;
    projectEnd?: string;
    requestStart?: string;
    requestEnd?: string;
    competencies: Array<ProjectCompetencyDTO>;
    members: Array<PlanMemberDTO>;
    comment?: string;
}
export namespace LookingForResourceDTO {
    export type ProjectStateEnum = 'PROSPECT' | 'ACTIVE' | 'PLANNED' | 'CLOSED';
    export const ProjectStateEnum = {
        Prospect: 'PROSPECT' as ProjectStateEnum,
        Active: 'ACTIVE' as ProjectStateEnum,
        Planned: 'PLANNED' as ProjectStateEnum,
        Closed: 'CLOSED' as ProjectStateEnum
    };
    export type StatusEnum = 'WAITING' | 'IN_PROGRESS' | 'DONE';
    export const StatusEnum = {
        Waiting: 'WAITING' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Done: 'DONE' as StatusEnum
    };
}


