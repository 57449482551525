import { Component, OnDestroy, ViewChild } from "@angular/core";
import { Observable, Subscription, forkJoin, map, switchMap, tap } from "rxjs";
import { NgbAccordionDirective, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import {
  CompetencyService,
  CompetencyDTO,
  ProjectCompetencyService,
  ProjectCompetencyDTO,
  CompetencyGroupDTO,
  CompetencyGroupService,
} from "src/shared/api/generated";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { NgxSpinnerService } from "ngx-spinner";
import { DisplayAndEditProjectCompetencyDto } from "src/shared/models/display-and-edit-project-competency.model";
import { AddOrEditCompetencyModalComponent } from "../add-or-edit-competency-modal/add-or-edit-competency-modal.component";
import { PermissionService } from "src/shared/services/permission.service";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
@Component({
  selector: "app-project-competency",
  templateUrl: "./project-competency.component.html",
  styleUrls: ["./project-competency.component.scss"],
})
export class ProjectCompetencyComponent implements OnDestroy {
  @ViewChild("basicCompetencyAccordion")
  basicCompetencyAccordion!: NgbAccordionDirective;
  @ViewChild("projectCompetencyAccordion")
  projectCompetencyAccordion!: NgbAccordionDirective;
  @ViewChild("nonProjectCompetencyAccordion")
  nonProjectCompetencyAccordion!: NgbAccordionDirective;
  searchText: string = "";
  filteredCompetencies: CompetencyDTO[] = [];
  errorFetchingCompetencies: boolean = false;
  editedProjectCompetency: DisplayAndEditProjectCompetencyDto =
    {} as DisplayAndEditProjectCompetencyDto;
  editProjectCompetenciesMode: boolean = false;
  levelEditedProjectCompetency: ProjectCompetencyDTO | null = null;
  newProjectCompetencyEditMode: boolean = false;
  competencyGroups: CompetencyGroupDTO[] = [];
  selectableCompetencyTypes: CompetencyGroupDTO[] = [];
  isAdmin: boolean = false;

  public competencies: CompetencyDTO[] = [];
  public projectCompetencies: ProjectCompetencyDTO[] = [];
  public filteredProjectCompetencies: ProjectCompetencyDTO[] = [];
  public nonProjectCompetencies: CompetencyDTO[] = [];
  public filteredNonProjectCompetencies: CompetencyDTO[] = [];

  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyService: CompetencyService,
    private modalService: NgbModal,
    private competencyManagerService: CompetencyManagerService,
    private competencyGroupService: CompetencyGroupService,
    private projectCompetencyService: ProjectCompetencyService,
    private confirmationModalService: ConfirmationModalService,
    private spinnerService: NgxSpinnerService,
    public permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.initialLoad();
    this.isAdmin = this.permissionService.checkAdmin();
    this.subscriptions.add(
      this.competencyManagerService.projectCompetency.subscribe(
        (data: DisplayAndEditProjectCompetencyDto) => {
          if (this.editedProjectCompetency.projectId !== data.projectId) {
            this.clearEditing();
          }
          this.editedProjectCompetency = data;
          if (data.projectId) {
            this.filterCompetencyArrays(this.competencies);
            this.editProjectCompetenciesMode = true;
          } else {
            this.editProjectCompetenciesMode = false;
          }
        }
      )
    );
  }

  initialLoad() {
    forkJoin([
      this.getRootCompetencyGroups(),
      this.getSelectableCompetencyGroups(),
      this.competencyService.getAllCompetencies(),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: ([rootGroups, selectableGroups, competencies]) => {
          this.competencyGroups = rootGroups;
          this.selectableCompetencyTypes = selectableGroups;
          this.filterCompetencyArrays(competencies);
        },
        error: () => (this.errorFetchingCompetencies = true),
      });
  }

  getRootCompetencyGroups() {
    return this.competencyGroupService.getAllRootCompetencyGroups();
  }

  getSelectableCompetencyGroups() {
    return this.competencyGroupService.getAllSelectableCompetencyGroups();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSearchInput() {
    const searchTerm = this.searchText.trim().toLowerCase();
    if (searchTerm === "") {
      this.filteredCompetencies = this.competencies;
      this.filteredProjectCompetencies = this.projectCompetencies;
      this.filteredNonProjectCompetencies = this.nonProjectCompetencies;
    } else {
      this.filteredCompetencies = this.competencies.filter((competency) =>
        competency.name.toLowerCase().includes(searchTerm)
      );
      this.filteredProjectCompetencies = this.projectCompetencies.filter(
        (competency) =>
          this.getCompetencyName(competency.competencyId)
            .toLowerCase()
            .includes(searchTerm)
      );
      this.filteredNonProjectCompetencies = this.nonProjectCompetencies.filter(
        (competency) => competency.name.toLowerCase().includes(searchTerm)
      );
      this.expandCategoriesWithSearchResult();
    }
  }

  collapseAllCategories() {
    if (this.editProjectCompetenciesMode) {
      this.projectCompetencyAccordion.collapseAll();
      this.nonProjectCompetencyAccordion.collapseAll();
    } else {
      this.basicCompetencyAccordion.collapseAll();
    }
  }

  expandCategoriesWithSearchResult() {
    for (let type of this.competencyGroups) {
      if (!this.editProjectCompetenciesMode) {
        this.expandBasicCompetencyCategories(type);
      } else if (this.editedProjectCompetency.displayOnly) {
        this.expandDisplayOnlyCompetencyCategories(type);
      } else {
        this.expandProjectAndNonProjectCompetencyCategories(type);
      }
    }
  }

  private expandProjectAndNonProjectCompetencyCategories(
    type: CompetencyGroupDTO
  ) {
    this.expandCompetencyCategoriesWithSearchResult(
      type,
      this.getFilteredCompetencies().map((c) =>
        this.convertProjectCompetencyToCompetency(c)
      ),
      this.projectCompetencyAccordion
    );
    this.expandCompetencyCategoriesWithSearchResult(
      type,
      this.filteredNonProjectCompetencies,
      this.nonProjectCompetencyAccordion
    );
  }

  private expandDisplayOnlyCompetencyCategories(type: CompetencyGroupDTO) {
    this.expandCompetencyCategoriesWithSearchResult(
      type,
      this.getFilteredCompetencies().map((c) =>
        this.convertProjectCompetencyToCompetency(c)
      ),
      this.projectCompetencyAccordion
    );
  }

  private expandBasicCompetencyCategories(type: CompetencyGroupDTO) {
    this.expandCompetencyCategoriesWithSearchResult(
      type,
      this.filteredCompetencies,
      this.basicCompetencyAccordion
    );
  }

  expandCompetencyCategoriesWithSearchResult(
    type: CompetencyGroupDTO,
    competencies: CompetencyDTO[],
    accordion: NgbAccordionDirective
  ) {
    const filteredCompetenciesNumber =
      this.getNumberOfCompetenciesInCategoryFromArray(competencies, type.id);
    if (filteredCompetenciesNumber !== 0) {
      accordion.expand(type.name);
    } else {
      accordion.collapse(type.name);
    }
  }

  onAddCompetency(competencyName: string) {
    if (this.editedProjectCompetency.displayOnly || !this.isAdmin) {
      return;
    }
    const modalRef = this.modalService.open(AddOrEditCompetencyModalComponent);
    modalRef.componentInstance.competency = {
      id: undefined,
      name: competencyName,
      competencyGroupId: this.selectableCompetencyTypes[0].id,
    };
    modalRef.componentInstance.competencyGroups =
      this.selectableCompetencyTypes;
    modalRef.componentInstance.createMode = true;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.searchText = result.name;
          this.getCompetencies();
        }
      },
      () => {}
    );
  }

  onEditCompetency(competency: CompetencyDTO) {
    if (this.editedProjectCompetency.displayOnly || !this.isAdmin) {
      return;
    }
    this.hasAssignments(competency.id!).subscribe((hasAssignments) => {
      if (!hasAssignments) {
        this.editCompetency(competency);
      } else {
        this.confirmationModalService
          .openConfirmationModal(
            "competency.confirm_update",
            "competency.update_dialog_message"
          )
          .then(
            (result) => {
              if (result) {
                this.editCompetency(competency);
              }
            },
            () => {}
          );
      }
    });
  }

  onEditProjectCompetencyName(competency: ProjectCompetencyDTO) {
    this.onEditCompetency(
      this.competencies.find((c) => c.id === competency.competencyId)!
    );
  }

  editCompetency(competency: CompetencyDTO) {
    const modalRef = this.modalService.open(AddOrEditCompetencyModalComponent);
    modalRef.componentInstance.competency = {
      id: competency.id,
      name: competency.name,
      competencyGroupId: competency.competencyGroup.id,
    };
    modalRef.componentInstance.competencyGroups =
      this.selectableCompetencyTypes;
    modalRef.componentInstance.createMode = false;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.getCompetencies();
        }
      },
      () => {}
    );
  }

  onDeleteCompetency(competencyId: number) {
    if (
      this.editedProjectCompetency.displayOnly ||
      this.levelEditedProjectCompetency ||
      !this.isAdmin
    ) {
      return;
    }
    this.hasAssignments(competencyId).subscribe((hasAssignments) => {
      if (!hasAssignments) {
        this.deleteCompetency(competencyId);
      } else {
        this.confirmationModalService.openConfirmationModal(
          "competency.delete_error",
          "competency.delete_dialog_message",
          true
        );
      }
    });
  }

  private hasAssignments(competencyId: number): Observable<boolean> {
    return this.competencyService
      .getNumberOfUsagesOfCompetency(competencyId)
      .pipe(map((numberOfAssignments: number) => numberOfAssignments > 0));
  }

  deleteCompetency(competencyId: number) {
    this.competencyService
      .deleteCompetency(competencyId)
      .pipe(
        switchMap(() =>
          this.competencyService
            .getAllCompetencies()
            .pipe(showLoadingSpinner(this.spinnerService))
        ),
        tap((result) => this.filterCompetencyArrays(result))
      )
      .subscribe({
        next: () => {
          this.competencyManagerService.setCompetencyDeleted();
          if (this.editedProjectCompetency.competencyList) {
            this.editedProjectCompetency.competencyList =
              this.editedProjectCompetency.competencyList.filter(
                (c) => c.competencyId !== competencyId
              );
          }
          this.refreshEditedProjectCompetency();
        },
        error: (error) => {
          if (
            error.error.message ===
            "Competency cannot be deleted because it's already in use."
          ) {
            this.confirmationModalService.openConfirmationModal(
              "competency.delete_error",
              "competency.delete_dialog_message",
              true
            );
          }
        },
      });
  }

  isAddButtonDisabled() {
    return (
      this.filteredCompetencies.filter(
        (competency) =>
          competency.name.toLowerCase() === this.searchText.trim().toLowerCase()
      ).length > 0 || this.searchText.trim().toLowerCase() === ""
    );
  }

  public getCompetencies(): void {
    this.competencyService
      .getAllCompetencies()
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (response) => {
          this.filterCompetencyArrays(response);
        },
        error: () => {
          this.errorFetchingCompetencies = true;
        },
      });
  }

  filterCompetencyArrays(competencyArray: CompetencyDTO[]) {
    this.competencies = [...competencyArray].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    if (this.editedProjectCompetency.projectId) {
      this.projectCompetencies = this.editedProjectCompetency.competencyList;
      this.nonProjectCompetencies = this.competencies;
      this.filteredProjectCompetencies = this.projectCompetencies;
      this.filteredNonProjectCompetencies = this.nonProjectCompetencies;
    }
    this.onSearchInput();
    this.errorFetchingCompetencies = false;
  }

  getFilteredCompetencies(): ProjectCompetencyDTO[] {
    if (this.editedProjectCompetency.projectId) {
      return this.filteredProjectCompetencies.sort((a, b) =>
        this.getCompetencyName(a.competencyId).localeCompare(
          this.getCompetencyName(b.competencyId)
        )
      );
    } else {
      return [];
    }
  }

  deleteEditedCompetency(competency: ProjectCompetencyDTO) {
    if (
      this.editedProjectCompetency.projectId &&
      !this.levelEditedProjectCompetency
    ) {
      this.deleteProjectCompetency(competency);
    }
  }

  deleteProjectCompetency(competency: ProjectCompetencyDTO) {
    this.projectCompetencyService
      .deleteProjectCompetency(competency.id!)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((_) => {
        this.removeFromeEditedProjectCompetencyList(competency);
        this.refreshEditedProjectCompetency();
        this.filterCompetencyArrays(this.competencies);
      });
  }

  createEditedCompetency(competency: CompetencyDTO) {
    if (
      this.editedProjectCompetency.projectId &&
      !this.levelEditedProjectCompetency
    ) {
      this.createProjectCompetency(competency);
    }
  }

  createProjectCompetency(competency: CompetencyDTO) {
    if (this.editedProjectCompetency.displayOnly) {
      return;
    }
    this.levelEditedProjectCompetency = {
      projectId: this.editedProjectCompetency.projectId!,
      competencyId: competency.id!,
      level: 5,
    };
    this.addToEditedProjectCompetencyList(this.levelEditedProjectCompetency);
    this.refreshEditedProjectCompetency();
    this.newProjectCompetencyEditMode = true;
    this.projectCompetencyAccordion.expand(
      this.getRootGroupName(competency.competencyGroup.rootGroupId!)
    );
  }

  getRootGroupName(id: number) {
    return this.competencyGroups.find((c) => c.id === id)!.name;
  }

  onStartEditingLevel(competency: ProjectCompetencyDTO) {
    if (
      this.editedProjectCompetency.displayOnly ||
      this.levelEditedProjectCompetency
    ) {
      return;
    }
    this.levelEditedProjectCompetency = { ...competency };
  }

  onCancelLevelEditing() {
    if (
      this.newProjectCompetencyEditMode &&
      this.levelEditedProjectCompetency
    ) {
      this.removeFromeEditedProjectCompetencyList(
        this.levelEditedProjectCompetency
      );
      this.refreshEditedProjectCompetency();
    }
    this.levelEditedProjectCompetency = null;
    this.newProjectCompetencyEditMode = false;
  }

  onSaveLevelEditing() {
    if (this.newProjectCompetencyEditMode) {
      this.saveNewProjectCompetency();
    } else {
      this.saveEditedProjectCompetency();
    }
  }

  saveNewProjectCompetency() {
    this.projectCompetencyService
      .createProjectCompetency(this.levelEditedProjectCompetency!)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (createdCompetency: ProjectCompetencyDTO) => {
          this.editedProjectCompetency.competencyList =
            this.editedProjectCompetency.competencyList.filter((c) => c.id);
          this.refreshEditedProjectCompetency();
          this.addToEditedProjectCompetencyList(createdCompetency);
          this.refreshEditedProjectCompetency();
          this.levelEditedProjectCompetency = null;
          this.newProjectCompetencyEditMode = false;
        },
        error: (error) => {
          this.openLevelEditingErrorModal(error);
        },
      });
  }

  openLevelEditingErrorModal(error: any) {
    if (
      error.error.message ===
      "This competency is already connected to this project with this level"
    ) {
      this.confirmationModalService.openConfirmationModal(
        "project.error.title",
        "project.error.duplicate_competency_with_given_level",
        true
      );
    } else if (error.error.message === "Level cannot be more than 5") {
      this.confirmationModalService.openConfirmationModal(
        "project.error.title",
        "project.error.competency_more_than_5",
        true
      );
    } else if (error.error.message === "Level cannot be less than 1") {
      this.confirmationModalService.openConfirmationModal(
        "project.error.title",
        "project.error.competency_less_than_1",
        true
      );
    }
  }

  saveEditedProjectCompetency() {
    this.projectCompetencyService
      .updateProjectCompetency(this.levelEditedProjectCompetency!)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (updatedCompetency: ProjectCompetencyDTO) => {
          this.editedProjectCompetency.competencyList.find(
            (c) => c.id === updatedCompetency.id
          )!.level = updatedCompetency.level;
          this.refreshEditedProjectCompetency();
          this.levelEditedProjectCompetency = null;
          this.newProjectCompetencyEditMode = false;
        },
        error: (error) => {
          this.openLevelEditingErrorModal(error);
        },
      });
  }

  private addToEditedProjectCompetencyList(competency: ProjectCompetencyDTO) {
    this.editedProjectCompetency.competencyList = [
      competency,
      ...this.editedProjectCompetency.competencyList,
    ].sort((a, b) =>
      this.getCompetencyName(a.competencyId).localeCompare(
        this.getCompetencyName(b.competencyId)
      )
    );
  }

  private removeFromeEditedProjectCompetencyList(
    competency: ProjectCompetencyDTO
  ) {
    this.editedProjectCompetency.competencyList =
      this.editedProjectCompetency.competencyList
        .filter((c) => c.id !== competency.id)
        .sort((a, b) =>
          this.getCompetencyName(a.competencyId).localeCompare(
            this.getCompetencyName(b.competencyId)
          )
        );
  }

  refreshEditedProjectCompetency() {
    this.competencyManagerService.setProjectCompetency(
      this.editedProjectCompetency.displayOnly,
      this.editedProjectCompetency.projectId!,
      this.editedProjectCompetency.projectName!,
      this.editedProjectCompetency.competencyList
    );
  }

  getCompetencyName(id: number): string {
    return (
      this.competencies.find((competency) => competency.id === id)?.name ?? ""
    );
  }

  convertProjectCompetencyToCompetency(
    projectCompetency: ProjectCompetencyDTO
  ): CompetencyDTO {
    return this.competencies.find(
      (competency) => competency.id === projectCompetency.competencyId
    )!;
  }

  clearEditing() {
    this.onCancelLevelEditing();
  }

  getNumberOfCompetenciesInCategoryFromArray(
    array: CompetencyDTO[],
    typeId: number
  ): number {
    return array
      .filter((c) => c.competencyGroup.rootGroupId === typeId)
      .sort((a, b) => a.name.localeCompare(b.name)).length;
  }

  getNonProjectCompetenciesInCategoryFromArray(
    array: CompetencyDTO[],
    typeId: number
  ): CompetencyDTO[] {
    return array
      .filter((c) => c.competencyGroup.rootGroupId === typeId)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getProjectCompetenciesInCategoryFromArray(
    array: ProjectCompetencyDTO[],
    typeId: number
  ): ProjectCompetencyDTO[] {
    return array
      .filter(
        (c) =>
          this.convertProjectCompetencyToCompetency(c).competencyGroup
            .rootGroupId === typeId
      )
      .sort((a, b) =>
        this.getCompetencyName(a.competencyId).localeCompare(
          this.getCompetencyName(b.competencyId)
        )
      );
  }
}
