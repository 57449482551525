<div class="modal-header">
  <h4 class="modal-title">
    {{ "basket.create_basket_modal.create_basket" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body main-container">
  <div class="form-row">
    <label>{{ "basket.create_basket_modal.basket_name" | translate }}</label>
    <input type="text" [(ngModel)]="basketToAdd.name" />
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary cancel-button"
    (click)="activeModal.close(false)"
  >
    {{ "basket.create_basket_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary ok-button"
    (click)="saveCompetency()"
    [disabled]="isCreateDisabled()"
  >
    {{ "basket.create_basket_modal.save" | translate }}
  </button>
</div>
