<td>
  <label class="text-overflow-dotted">{{
    lookingForResource.projectName
  }}</label>
</td>
<td>
  <select
    [(ngModel)]="editLookingForResource.assigneeId"
    (change)="saveLookingForResource()"
    (click)="inputClicked($event)"
  >
    <option *ngFor="let user of users" [value]="user.id">
      {{ user.name }}
    </option>
  </select>
</td>
<td>
  <select
    [(ngModel)]="editLookingForResource.status"
    (change)="saveLookingForResource()"
    (click)="inputClicked($event)"
  >
    <option value="WAITING">
      {{ "home.looking_for_resources.status.waiting" | translate }}
    </option>
    <option value="IN_PROGRESS">
      {{ "home.looking_for_resources.status.in_progress" | translate }}
    </option>
    <option value="DONE">
      {{ "home.looking_for_resources.status.done" | translate }}
    </option>
  </select>
</td>
<td class="text-center">
  <fa-icon
    *ngIf="lookingForResource.comment && lookingForResource.comment !== ''"
    class="comment-icon"
    (click)="openCommentEditModal($event)"
    [icon]="['fas', 'comment']"
  ></fa-icon>
  <fa-icon
    *ngIf="!lookingForResource.comment || lookingForResource.comment === ''"
    class="comment-icon"
    (click)="openCommentEditModal($event)"
    [icon]="['far', 'comment']"
  ></fa-icon>
</td>
<td class="text-center text-overflow-dotted">
  <label>{{ projectStateLabel }}</label>
</td>
<td class="text-center text-overflow-dotted">
  <label>{{ lookingForResource.projectStart }}</label>
</td>
<td class="text-center text-overflow-dotted">
  <label>{{ lookingForResource.projectEnd }}</label>
</td>
<td
  (dblclick)="setEditStateTrue('requestStart')"
  class="text-center text-overflow-dotted"
>
  <input
    *ngIf="this.editedCell === 'requestStart'"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="saveLookingForResource()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="saveLookingForResource()"
    [(ngModel)]="editLookingForResource.requestStart"
    (click)="inputClicked($event)"
  />
  <label *ngIf="this.editedCell !== 'requestStart'">{{
    lookingForResource.requestStart
  }}</label>
</td>
<td
  (dblclick)="setEditStateTrue('requestEnd')"
  class="text-center text-overflow-dotted"
>
  <input
    *ngIf="this.editedCell === 'requestEnd'"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="saveLookingForResource()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="saveLookingForResource()"
    [(ngModel)]="editLookingForResource.requestEnd"
    (click)="inputClicked($event)"
  />
  <label *ngIf="this.editedCell !== 'requestEnd'">{{ lookingForResource.requestEnd }}</label>
</td>
<td
  (dblclick)="setEditStateTrue('fte')"
  class="text-center text-overflow-dotted"
>
  <input
    *ngIf="this.editedCell === 'fte'"
    type="number"
    step="0.25"
    min="0"
    appPrefixFocusAndSelect
    (keydown.enter)="saveLookingForResource()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="saveLookingForResource()"
    [(ngModel)]="editLookingForResource.fte"
    (click)="inputClicked($event)"
  />
  <label *ngIf="this.editedCell !== 'fte'">{{ lookingForResource.fte }}</label>
</td>
<td class="text-center">
  <div
    (click)="selectCompetencyCell($event)"
    [ngClass]="{
      selection: selectedCellProjectId === lookingForResource.projectId,
      'search-find': isCompetencySearchResult()
    }"
    class="competency-number"
  >
    {{
      lookingForResource.competencies.length
        ? lookingForResource.competencies.length
        : 0
    }}
  </div>
</td>
<td class="text-center">
  <div
    (click)="selectMemberCell($event)"
    [ngClass]="{
      selection: selectedMemberCellProjectId === lookingForResource.projectId,
      'search-find': isMemberSearchResult()
    }"
    class="members-number"
  >
    {{ lookingForResource.members.length }}
  </div>
</td>
