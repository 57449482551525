import { Component } from "@angular/core";
import { ProfileDTO } from "src/shared/api/generated";

@Component({
  selector: "app-profile-export",
  templateUrl: "./profile-export.component.html",
  styleUrls: ["./profile-export.component.scss"],
})
export class ProfileExportComponent {
  profileExportQueue: ProfileDTO[] = [];


  profileExportQueueChanged(event: ProfileDTO[]) {
    this.profileExportQueue = event;
  }
}
