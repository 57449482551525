<div class="modal-header">
  <h4 class="modal-title">
    {{ "home.looking_for_project.create_modal.title" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body main-container">
  <div class="form-row">
    <label for="employee">{{
      "home.looking_for_project.create_modal.employee" | translate
    }}</label>
    <input
      id="employee"
      type="text"
      [(ngModel)]="selectedEmployee"
      [ngbTypeahead]="searchEmployee"
      [resultFormatter]="formatter"
      [inputFormatter]="formatter"
      [editable]="false"
      (focus)="employeeFocus$.next($any($event).target.value)"
      (click)="employeeClick$.next($any($event).target.value)"
      #employeeInstance="ngbTypeahead"
    />
  </div>
  <div class="form-row">
    <label for="assignee">{{
      "home.looking_for_project.create_modal.assignee" | translate
    }}</label>
    <input
      id="assignee"
      type="text"
      [(ngModel)]="selectedAssignee"
      [ngbTypeahead]="searchAssignee"
      [resultFormatter]="formatter"
      [inputFormatter]="formatter"
      [editable]="false"
      (focus)="assigneeFocus$.next($any($event).target.value)"
      (click)="assigneeClick$.next($any($event).target.value)"
      #assigneeInstance="ngbTypeahead"
    />
  </div>
  <div class="form-row">
    <label for="freeFrom">{{
      "home.looking_for_project.create_modal.free_from" | translate
    }}</label>
    <input
      id="freeFrom"
      [(ngModel)]="newLookingForProject.freeFrom"
      type="date"
    />
  </div>
</div>
<div *ngIf="error" class="error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">{{
    errorMessage
  }}</ngb-alert>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary cancel-button"
    (click)="activeModal.close(false)"
  >
    {{ "home.looking_for_project.create_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary ok-button"
    [disabled]="isDisabled()"
    (click)="saveNewEmployee()"
  >
    {{ "home.looking_for_project.create_modal.save" | translate }}
  </button>
</div>
