import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProfileDTO } from "src/shared/api/generated";

@Component({
  selector: "app-table-profile-export-queue",
  templateUrl: "./table-profile-export-queue.component.html",
  styleUrls: ["./table-profile-export-queue.component.scss"],
})
export class TableProfileExportQueueComponent {
  @Input() profiles: ProfileDTO[] = [];
  @Output() profileRemovedFromQueue = new EventEmitter<ProfileDTO[]>();

  onProfileRemovedFromQueue(profileId: number) {
    this.profiles = this.profiles.filter((p) => p.id !== profileId);
    this.profileRemovedFromQueue.emit(this.profiles);
  }
}
