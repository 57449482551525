/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EducationDTO } from './educationDTO';
import { EmployeeDTOCompetencies } from './employeeDTOCompetencies';


export interface EmployeeDTO { 
    id?: number;
    name: string;
    email: string;
    externalPartner?: string;
    active?: boolean;
    internal?: boolean;
    planned?: boolean;
    companyId: number;
    competencies: EmployeeDTOCompetencies;
    seniority?: EmployeeDTO.SeniorityEnum;
    positionId?: number;
    averageOfTop3Competencies?: number;
    education?: Array<EducationDTO>;
}
export namespace EmployeeDTO {
    export type SeniorityEnum = 'JUNIOR' | 'JUNIOR_MEDIOR' | 'MEDIOR' | 'MEDIOR_SENIOR' | 'SENIOR';
    export const SeniorityEnum = {
        Junior: 'JUNIOR' as SeniorityEnum,
        JuniorMedior: 'JUNIOR_MEDIOR' as SeniorityEnum,
        Medior: 'MEDIOR' as SeniorityEnum,
        MediorSenior: 'MEDIOR_SENIOR' as SeniorityEnum,
        Senior: 'SENIOR' as SeniorityEnum
    };
}


