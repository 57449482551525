<div class="modal-header">
  <h4 class="modal-title">
    {{ "home.looking_for_resources.create_modal.title" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body main-container">
  <div class="form-row">
    <label for="project">{{
      "home.looking_for_resources.create_modal.project" | translate
    }}</label>
    <input
      id="project"
      type="text"
      [(ngModel)]="selectedProject"
      [ngbTypeahead]="searchProject"
      [resultFormatter]="formatter"
      [inputFormatter]="formatter"
      [editable]="false"
      (focus)="projectFocus$.next($any($event).target.value)"
      (click)="projectFocus$.next($any($event).target.value)"
      #employeeInstance="ngbTypeahead"
    />
  </div>
  <div class="form-row">
    <label for="assignee">{{
      "home.looking_for_resources.create_modal.assignee" | translate
    }}</label>
    <input
      id="assignee"
      type="text"
      [(ngModel)]="selectedAssignee"
      [ngbTypeahead]="searchAssignee"
      [resultFormatter]="formatter"
      [inputFormatter]="formatter"
      [editable]="false"
      (focus)="assigneeFocus$.next($any($event).target.value)"
      (click)="assigneeClick$.next($any($event).target.value)"
      #assigneeInstance="ngbTypeahead"
    />
  </div>
  <div class="form-row">
    <label for="requestStart">{{
      "home.looking_for_resources.create_modal.start" | translate
    }}</label>
    <input
      [(ngModel)]="newLookingForResource.requestStart"
      id="requestStart"
      type="date"
    />
  </div>
  <div class="form-row">
    <label for="requestEnd">{{
      "home.looking_for_resources.create_modal.end" | translate
    }}</label>
    <input
      [(ngModel)]="newLookingForResource.requestEnd"
      id="requestEnd"
      type="date"
    />
  </div>
  <div class="form-row">
    <label for="fte">{{
      "home.looking_for_resources.create_modal.fte" | translate
    }}</label>
    <input
      [(ngModel)]="newLookingForResource.fte"
      id="fte"
      type="number"
      step="0.25"
      min="0"
    />
  </div>
</div>
<div *ngIf="error" class="error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">{{
    errorMessage
  }}</ngb-alert>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary cancel-button"
    (click)="activeModal.close(false)"
  >
    {{ "home.looking_for_resources.create_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary ok-button"
    [disabled]="isDisabled()"
    (click)="saveNewProject()"
  >
    {{ "home.looking_for_resources.create_modal.save" | translate }}
  </button>
</div>
