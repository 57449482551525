<div class="d-flex flex-column w-100 h-100 base-container">
  <div class="content mw-100 mh-100 d-flex flex-grow-1 pt-3 flex-column">
    <div class="title-container">
      <img src="..\..\assets\img\squirrel_standing_tie.png" class="title-squirrel-image">
      <h1 class="title">{{ "plan.title" | translate }}</h1>
    </div>
    <app-company-separated-display
      [animation]="false"
      [companies]="this.visibleCompanies"
      class="flex-grow-1 d-flex flex-column pb-3 tabs"
      (companyAddedEvent)="refreshCompanies()"
    >
      <ng-template companyMarker *ngFor="let company of visibleCompanies">
        <app-plan-timeline [company]="company" class="timeline h-100"></app-plan-timeline>
      </ng-template>
    </app-company-separated-display>
  </div>
</div>
