<div (mouseleave)="showDropdown = false">
  <div>
    <button
      [disabled]="disabled"
      class="drop-toggle btn btn-sm flat"
      (click)="showDropdown = !showDropdown"
    >
      <span *ngIf="getCheckedCompanyNames().length <= 0">Select</span>
      <span
        class="selected-list text-overflow-dotted"
        *ngIf="getCheckedCompanyNames().length > 0"
        >{{ getCheckedCompanyNames().join(", ") }}</span
      >
      <i class="fa fa-angle-down"></i>
    </button>
    <div class="drop-show" *ngIf="showDropdown">
      <label
        class="w-100 text-overflow-dotted"
        *ngFor="let a of checkedCompanies | keyvalue"
      >
        <input
          class="form-check-input"
          type="checkbox"
          [(ngModel)]="a.value"
          (change)="getSelectedValue(a.value, a.key)"
        />
        {{ a.key.name }}
      </label>
    </div>
  </div>
</div>
