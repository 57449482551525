<div
  [ngClass]="{ 'competency-sidebar-collapsed': isCollapsed }"
  class="competency-sidebar rounded-start-3 shadow"
>
  <div class="arrow-icon ps-2" (click)="toggleCompetencySidebar()">
    <fa-icon
      [icon]="isCollapsed ? ['fas', 'chevron-left'] : ['fas', 'chevron-right']"
    ></fa-icon>
  </div>
  <div *ngIf="!isCollapsed" class="competency-sidebar-content mt-5">
    <h3 class="competency-sidebar-title fw-bold pb-1">
      {{ "competency.competencies" | translate }}
    </h3>
    <ng-content></ng-content>
  </div>
</div>

