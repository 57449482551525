import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { DisplayAndEditEmployeeCompetencyDto } from "src/shared/models/display-and-edit-employee-competency.model";
import { DisplayAndEditProjectCompetencyDto } from "src/shared/models/display-and-edit-project-competency.model";

@Component({
  selector: "app-competency",
  templateUrl: "./competency.component.html",
  styleUrls: ["./competency.component.scss"],
})
export class CompetencyComponent implements OnInit, OnDestroy {
  isCollapsed: boolean = true;
  projectCompetencyId: number | null = null;

  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyManagerService: CompetencyManagerService
  ){}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.competencyManagerService.employeeCompetency.subscribe(
        (data: DisplayAndEditEmployeeCompetencyDto) => {
          if (data.employeeId) {
            this.isCollapsed = false;
          } 
        }
      )
    );
    this.subscriptions.add(
      this.competencyManagerService.projectCompetency.subscribe(
        (data: DisplayAndEditProjectCompetencyDto) => {
          if (data.projectId && this.projectCompetencyId !== data.projectId) {
            this.isCollapsed = false;
            this.projectCompetencyId = data.projectId;
          }
        }
      )
    );
  }

  toggleCompetencySidebar(): void {
    this.isCollapsed = !this.isCollapsed;
    if(this.isCollapsed){
      this.competencyManagerService.clearBothCompetencies();
      this.projectCompetencyId = null;
    }
  }
}
