import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-competency-pill-container",
  templateUrl: "./competency-pill-container.component.html",
  styleUrls: ["./competency-pill-container.component.scss"],
})
export class CompetencyPillContainerComponent {
  @Input() competencies: any[] = [];
  @Input() allCompetencies: any[] = [];
  @Input() employeeCompetencies: any[] = [];
  @Input() commands: any[] = [];
  @Input() isAddPillButtonActive: boolean = false;
  @Input() isDragActive: boolean = false;
  @Output() newCompetencyEvent: EventEmitter<any> = new EventEmitter();

  constructor() {}

  getUnselectedCompetencies(): any[] {
    return this.allCompetencies.filter(
      (competency) =>
        !this.competencies.some(
          (selectedCompetency) => selectedCompetency.id === competency.id
        )
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.competencies, event.previousIndex, event.currentIndex);
  }

  setNewCompetency(competency: any) {
    this.newCompetencyEvent.emit(competency);
  }
}
