import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import {
  CompetencyGroupDTO,
  CompetencyGroupService,
  ReadOnlyProfileCompetencyDTO,
  ReadOnlyProfileDTO,
  ReadOnlyProfileService,
} from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-read-only-profile-modal",
  templateUrl: "./read-only-profile-modal.component.html",
  styleUrls: ["./read-only-profile-modal.component.scss"],
})
export class ReadOnlyProfileModalComponent {
  @Input() employeeId: number | undefined;

  profile: ReadOnlyProfileDTO = {};

  technologicalCompetencyGroup: CompetencyGroupDTO = {} as CompetencyGroupDTO;
  languageCompetencyGroup: CompetencyGroupDTO = {} as CompetencyGroupDTO;

  constructor(
    private readOnlyProfileService: ReadOnlyProfileService,
    private competencyGroupService: CompetencyGroupService,
    private spinnerService: NgxSpinnerService,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    if (this.employeeId) {
      this.readOnlyProfileService
        .getReadOnlyProfileForEmployee(this.employeeId)
        .pipe(showLoadingSpinner(this.spinnerService))
        .subscribe({ next: (profile) => (this.profile = profile) });
    }

    this.getTechnologicalAndLanguageCompetencyGroups();
  }

  getTechnologicalAndLanguageCompetencyGroups() {
    this.competencyGroupService.getAllCompetencyGroups().subscribe({
      next: (competencyGroups) => {
        this.technologicalCompetencyGroup = competencyGroups.find(cg => cg.name === "Technological")!;
        this.languageCompetencyGroup = competencyGroups.find(cg => cg.name === "Language")!;
      }
    });
  }

  getProjects() {
    return this.profile.projects?.sort((a, b) =>
      b.year!.localeCompare(a.year!)
    );
  }

  getTechnologicalCompetencies(): ReadOnlyProfileCompetencyDTO[] {
    return (
      this.profile.competencies
        ?.filter(
          (c) => c.competencyDTO?.competencyGroup.rootGroupId === this.technologicalCompetencyGroup.id
        )
        .sort((a, b) => b.level! - a.level!) ?? []
    );
  }

  getLanguageCompetencies(): ReadOnlyProfileCompetencyDTO[] {
    return (
      this.profile.competencies
        ?.filter(
          (c) => c.competencyDTO?.competencyGroup.rootGroupId === this.languageCompetencyGroup.id
        )
        .sort((a, b) => b.level! - a.level!) ?? []
    );
  }

}
