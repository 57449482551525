<div class="main-container">
  <div
    class="export-table"
    id="queue"
    cdkDropList
    [cdkDropListData]="profiles"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="h-100">
      <app-table-profile-export-queue
        [profiles]="profiles"
        (profileRemovedFromQueue)="onProfileRemovedFromQueue($event)"
      ></app-table-profile-export-queue>
    </div>
  </div>
  <hr class="divider" />
  <div class="export-controls">
    <div class="anonym-employee">
      <label class="w-50">{{
        "profile_export.anonymize_employee" | translate
      }}</label>
      <div class="form-check form-switch centered-switch w-50">
        <input
          [(ngModel)]="anonymizeEmployees"
          class="form-check-input"
          type="checkbox"
          role="switch"
        />
      </div>
    </div>
    <div class="alternate-project-name">
      <label class="w-50">{{
        "profile_export.alternative_project_name" | translate
      }}</label>
      <div class="form-check form-switch centered-switch w-50">
        <input
          [(ngModel)]="useAlternateProjectName"
          class="form-check-input"
          type="checkbox"
          role="switch"
        />
      </div>
    </div>
    <div class="display-project-description">
      <label class="w-50">{{
        "profile_export.display_project_description" | translate
      }}</label>
      <div class="form-check form-switch centered-switch w-50">
        <input
          [(ngModel)]="displayProjectDescription"
          class="form-check-input"
          type="checkbox"
          role="switch"
        />
      </div>
    </div>
    <div class="template">
      <label class="w-50"> {{ "profile_export.template" | translate }}</label>
      <select [(ngModel)]="templateToUse" class="template-select w-50">
        <option value="gloster_pdf_template">Gloster template</option>
        <option value="minero_pdf_template">Gloster-Minero template</option>
        <option value="lanoga_pdf_template">Gloster-Lanoga template</option>
        <option value="p92_pdf_template">Gloster-P92 template</option>
        <option value="cloud_pdf_template">Gloster Cloud template</option>
      </select>
    </div>
    <div class="contact-email">
      <label class="w-50"> {{ "profile_export.contact_email" | translate }}</label>
      <input [(ngModel)]="contactEmail" type="email" class="w-50">
    </div>
    <div class="mt-auto ms-auto">
      <button (click)="exportToPdf()" [disabled]="isExportDisabled()" class="btn btn-primary export-button">
        {{ "profile_export.export" | translate }}
      </button>
    </div>
  </div>
</div>
