import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CompanyDTO, CompanyService } from "src/shared/api/generated";

@Component({
  selector: "app-company-multi-select-dropdown",
  templateUrl: "./company-multi-select-dropdown.component.html",
  styleUrls: ["./company-multi-select-dropdown.component.scss"],
})
export class CompanyMultiSelectDropdownComponent {
  @Output() shareCheckedList = new EventEmitter<any>();
  @Output() shareIndividualCheckedList = new EventEmitter<any>();
  @Input() disabled: boolean = false;

  checkedCompanies: Map<CompanyDTO, boolean> = new Map();

  showDropdown: boolean = false;

  constructor(private companyService: CompanyService) {}

  ngOnInit() {
    this.companyService.getAllCompanies().subscribe((companies) => {
      companies.forEach((c) => this.checkedCompanies.set(c, true));

      this.shareCheckedlist();
    });
  }

  getSelectedValue(status: boolean, value: CompanyDTO) {
    this.checkedCompanies.set(value, status);
    if (!this.getCheckedCompanies().length) {
      this.checkedCompanies.forEach((s, k) =>
        this.checkedCompanies.set(k, true)
      );
    }

    this.shareCheckedlist();
  }

  getCheckedCompanyNames() {
    return Array.from(this.checkedCompanies.entries())
      .filter((e) => e[1])
      .map((c) => c[0].name);
  }

  getCheckedCompanies() {
    return Array.from(this.checkedCompanies.entries())
      .filter((e) => e[1])
      .map((e) => e[0]);
  }

  shareCheckedlist() {
    this.shareCheckedList.emit(this.getCheckedCompanies());
  }
}
