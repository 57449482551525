import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { UserToken } from '../models/user-token.model';
import { ConfigurationService } from './configuration.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  private _user: BehaviorSubject<UserToken> = new BehaviorSubject<UserToken>({} as UserToken);

  get user() {
    return this._user;
  }


  constructor(private readonly configService: ConfigurationService) {
    if (!!this.configService.fetchToken('accessToken')) {
      const userToken = jwt_decode<UserToken>(this.configService.fetchToken('accessToken'));
      this._user.next(userToken);
    }
  }

  public decodeAndSaveUser(token: string): boolean {
    const userToken = jwt_decode<UserToken>(token);
    this._user.next(userToken);
    if (userToken) {
      this.configService.setToken({accessToken: token});
      return true;
    } else {
      return false;
    }
  }

}
