import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  BasketDTO,
  CompetencyDTO,
  CompetencyService,
  ProfileDTO,
} from "src/shared/api/generated";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/shared/directives/ngbd-sortable-header.directive";

@Component({
  selector: "app-table-basket-employee",
  templateUrl: "./table-basket-employee.component.html",
  styleUrls: ["./table-basket-employee.component.scss"],
})
export class TableBasketEmployeeComponent {
  @Input() basketId: number | undefined;
  @Input() profiles: ProfileDTO[] = [];
  @Input() profileExportQueue: ProfileDTO[] = [];
  @Output() profileRemoved = new EventEmitter<BasketDTO>();
  @Output() profileUpdatedInBasket = new EventEmitter<{basketId: number, profiles: ProfileDTO[]}>();

  allCompetencies: CompetencyDTO[] = [];

  constructor(public competencyService: CompetencyService) {}

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> =
    new QueryList();
  onSort({ column, direction }: SortEvent) {
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = "";
      }
    }

    if (direction === "" || column === "") {
      this.profiles = [...this.profiles].sort((a, b) => {
        return a.employeeName.localeCompare(b.employeeName);
      });
    } else {
      this.profiles = [...this.profiles].sort((a, b) => {
        const res =
          column === "email"
            ? a.employeeEmail.localeCompare(b.employeeEmail)
            : a.employeeName.localeCompare(b.employeeName);
        return direction === "asc" ? res : -res;
      });
    }
  }

  onProfileRemoved(basket: BasketDTO) {
    this.profileRemoved.emit(basket);
  }

  onProfileUpdatedInBasket(profile: ProfileDTO) {
    this.profiles.find((p) => p.id === profile.id)!.done = profile.done;
    this.profileUpdatedInBasket.emit({
      basketId: this.basketId!,
      profiles: this.profiles,
    });
  }
}
