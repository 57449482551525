import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, map } from "rxjs";
import {
  CompetencyDTO,
  CompetencyGroupDTO,
  CompetencyService,
} from "src/shared/api/generated";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";

@Component({
  selector: "[app-tr-competency-management]",
  templateUrl: "./tr-competency-management.component.html",
  styleUrls: ["./tr-competency-management.component.scss"],
})
export class TrCompetencyManagementComponent implements OnInit {
  @Input() competency: CompetencyDTO = {} as CompetencyDTO;
  @Input() competencyGroups: CompetencyGroupDTO[] = [];
  @Output() competencyModified = new EventEmitter<void>();
  @Output() competencyAlreadyExists = new EventEmitter<void>();

  editedCompetency: CompetencyDTO = {} as CompetencyDTO;
  editedCell: string = "";

  constructor(
    private competencyService: CompetencyService,
    private confirmationModalService: ConfirmationModalService
  ) {}

  ngOnInit(): void {
    this.resetEditedCompetency();
  }

  saveCompetency() {
    if (this.editedCompetency.name === "") {
      this.setEditStateFalse();
      return;
    }

    this.competencyService
      .updateCompetency({
        id: this.editedCompetency.id,
        name: this.editedCompetency.name,
        competencyGroupId: this.editedCompetency.competencyGroup.id,
      })
      .subscribe({
        next: () => {
          this.setEditStateFalse();
          this.competencyModified.emit();
        },
        error: (error) => {
          if (
            error.error.message === "Competency already exists with the name"
          ) {
            this.competencyAlreadyExists.emit();
          }
        },
      });
  }

  onDeleteCompetency(competencyId: number) {
    this.hasAssignments(competencyId).subscribe((hasAssignments) => {
      if (!hasAssignments) {
        this.deleteCompetency(competencyId);
        return;
      }

      this.confirmationModalService.openConfirmationModal(
        "competency.delete_error",
        "competency.delete_dialog_message",
        true
      );
    });
  }

  hasAssignments(competencyId: number): Observable<boolean> {
    return this.competencyService
      .getNumberOfUsagesOfCompetency(competencyId)
      .pipe(map((numberOfAssignments: number) => numberOfAssignments > 0));
  }

  deleteCompetency(competencyId: number) {
    this.competencyService.deleteCompetency(competencyId).subscribe({
      next: () => {
        this.competencyModified.emit();
      },
      error: (error) => {
        if (
          error.error.message ===
          "Competency cannot be deleted because it's already in use."
        ) {
          this.confirmationModalService.openConfirmationModal(
            "competency.delete_error",
            "competency.delete_dialog_message",
            true
          );
        }
      },
    });
  }

  setEditStateFalse() {
    this.editedCell = "";
    this.resetEditedCompetency();
  }

  setEditStateTrue(key: string) {
    this.editedCell = key;
  }

  resetEditedCompetency() {
    this.editedCompetency = JSON.parse(JSON.stringify(this.competency));
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }
}
