<div
  class="pill-container d-flex flex-row flex-wrap"
  cdkDropList
  cdkDropListOrientation="mixed"
  (cdkDropListDropped)="drop($event)"
  [cdkDropListDisabled]="!isDragActive"
>
  <div *ngFor="let competency of competencies" cdkDrag>
    <app-competency-pill
      *cdkDragPreview
      class="custom-preview"
      [commands]="commands"
      [competency]="competency"
    ></app-competency-pill>
    <app-competency-pill
      [commands]="commands"
      [competency]="competency"
    ></app-competency-pill>
  </div>
  <app-new-competency-pill
    *ngIf="isAddPillButtonActive"
    [competencies]="getUnselectedCompetencies()"
    [employeeCompetencies]="employeeCompetencies"
    (newCompetencyEvent)="setNewCompetency($event)"
  ></app-new-competency-pill>
</div>
