import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  OperatorFunction,
} from "rxjs";

interface NewCompetency {
  competency: any;
  level: number | undefined;
}

@Component({
  selector: "app-new-competency-pill",
  templateUrl: "./new-competency-pill.component.html",
  styleUrl: "./new-competency-pill.component.scss",
})
export class NewCompetencyPillComponent {
  @Input() competency: any;
  @Input() competencies: any[] = [];
  @Input() employeeCompetencies: any[] = [];
  @Output() newCompetencyEvent: EventEmitter<NewCompetency> =
    new EventEmitter();
  isCompetencySelectActive: boolean = false;
  isCompetencyLevelSelectActive: boolean = false;
  selectedCompetency: any;
  newCompetencyLevel: number | undefined = undefined;

  constructor() {}

  checkChoosenCompetency($event: any): void {
    if (
      this.employeeCompetencies.some(
        (employeeCompetency) => employeeCompetency.id === $event.item.id!
      )
    ) {
      this.addNewCompetency($event.item);
      this.clearCompetencySelect($event);
    } else {
      this.competency = $event.item;
      this.competency = $event.item;
      this.newCompetencyLevel = 1;
      this.clearCompetencySelect($event);
      this.isCompetencyLevelSelectActive = true;
    }
  }

  onSaveLevelEditing(): void {
    this.addNewCompetency(this.competency);
    this.clearLevelSelect();
  }

  addNewCompetency(competency: any): void {
    this.newCompetencyEvent.emit({
      competency: competency,
      level: this.newCompetencyLevel,
    });
  }

  openNewCompetencyInput(): void {
    this.isCompetencySelectActive = true;
  }

  clearCompetencySelect($event: any): void {
    this.isCompetencySelectActive = false;
    $event.preventDefault();
    this.selectedCompetency = null;
  }

  clearLevelSelect(): void {
    this.isCompetencyLevelSelectActive = false;
    this.newCompetencyLevel = undefined;
  }

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => this.sortAndFilterCompetencies(term))
    );
  };

  sortAndFilterCompetencies(term: string): any[] {
    return term.length < 1
      ? []
      : this.competencies
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
          .filter((v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .slice(0, 10);
  }

  formatter = (x: any) => x.name!;
}
