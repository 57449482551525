import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProfileDTO } from "src/shared/api/generated";

@Component({
  selector: "[app-tr-profile-export-queue]",
  templateUrl: "./tr-profile-export-queue.component.html",
  styleUrls: ["./tr-profile-export-queue.component.scss"],
})
export class TrProfileExportQueueComponent {
  @Input() profile: ProfileDTO = {} as ProfileDTO;
  @Output() profileRemovedFromQueue = new EventEmitter<number>();

  removeProfileFromQueue() {
    this.profileRemovedFromQueue.emit(this.profile.id);
  }
}
