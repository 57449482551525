import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-education-modal",
  templateUrl: "./education-modal.component.html",
  styleUrls: ["./education-modal.component.scss"],
})
export class EducationModalComponent {
  @Input() employeeName: string = "";
  @Input() employeeId!: number;
  error: boolean = false;
  errorMessage: string = "";
  educationModified: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService
  ) {}

  onEducationSaved() {
    this.closeAlert();
    this.educationModified = true;
  }

  onEducationSaveFailed(error: string) {
    if (error === "Missing or empty name") {
      this.error = true;
      this.errorMessage = this.translate.instant(
        "employee.education_modal.error.missing_name"
      );
    }
  }

  closeAlert() {
    this.error = false;
    this.errorMessage = "";
  }
}
