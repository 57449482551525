import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  ProjectCompetencyDTO,
  ProjectTableDTO,
} from "src/shared/api/generated";
import { DisplayAndEditProjectCompetencyDto } from "src/shared/models/display-and-edit-project-competency.model";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { isDeepEqual } from "src/shared/util/is-deep-equal";
import { ProjectMembersModalComponent } from "../../project-members-modal/project-members-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";

@Component({
  selector: "[app-tr-project]",
  templateUrl: "./tr-project.component.html",
  styleUrls: ["./tr-project.component.scss"],
})
export class TrProjectComponent implements OnInit, OnDestroy {
  @Input() project: ProjectTableDTO = {} as ProjectTableDTO;
  @Output() projectSaved = new EventEmitter<ProjectTableDTO>();
  @Input() searchTerm: string | undefined = "";
  @Input() search: boolean = false;
  @Input() allCompetencies: Map<number, string> = new Map();
  @Input() createMode: boolean = false;
  @Input() cloneMode: boolean = false;

  editProject: ProjectTableDTO = {} as ProjectTableDTO;
  selectedCellProjectId: number | null = null;
  selectedMemberCellProjectId: number | null = null;
  competencies: ProjectCompetencyDTO[] = [];

  editedCell: string = "";
  saved: boolean = false;
  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyManagerService: CompetencyManagerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.resetEditedProject();
    if (this.createMode || this.cloneMode) {
      this.competencyManagerService.clearProjectCompetency();
    }
    this.subscriptions.add(
      this.competencyManagerService.projectCompetency.subscribe(
        (data: DisplayAndEditProjectCompetencyDto) => {
          this.selectedCellProjectId = data.projectId;
          if (data.projectId === this.project.id) {
            this.competencies = data.competencyList;
          }
        }
      )
    );
    this.competencies = this.project.competencies ?? [];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  saveProject() {
    if (this.createMode || this.cloneMode) {
      return;
    }
    if (this.saved) {
      return;
    }
    if (
      isDeepEqual(this.editProject, this.project) ||
      this.editProject.name.trim() === "" ||
      this.editProject.start?.trim() === "" ||
      this.editProject.end?.trim() === ""
    ) {
      this.setEditStateFalse();
      return;
    }
    this.saved = true;

    this.projectSaved.emit(this.editProject);
    this.setEditStateFalse();
  }

  selectCompetencyCell($event: MouseEvent) {
    this.inputClicked($event);
    if (!this.project.id || this.createMode || this.cloneMode) {
      return;
    }
    if (this.project.id === this.selectedCellProjectId) {
      this.competencyManagerService.clearProjectCompetency();
      return;
    }
    this.competencyManagerService.setProjectCompetency(
      false,
      this.project.id,
      this.project.name,
      this.competencies
    );
  }

  selectMemberCell($event: MouseEvent) {
    this.inputClicked($event);
    if (!this.project.id || this.createMode || this.cloneMode) {
      return;
    }
    const modalRef = this.modalService.open(ProjectMembersModalComponent, {
      size: "lg",
      keyboard: false,
    });
    modalRef.componentInstance.projectName = this.project.name;
    modalRef.componentInstance.projectMembers = this.project.members;
    modalRef.componentInstance.allCompetencies = this.allCompetencies;
    this.selectedMemberCellProjectId = this.project.id;
    modalRef.closed.subscribe(() => (this.selectedMemberCellProjectId = null));
    modalRef.dismissed.subscribe(
      () => (this.selectedMemberCellProjectId = null)
    );
  }

  setEditStateFalse() {
    if (this.createMode || this.cloneMode) {
      return;
    }
    this.resetEditedProject();
    this.editedCell = "";
    this.saved = false;
  }

  setEditStateTrue(key: string) {
    if (this.createMode || this.cloneMode) {
      return;
    }
    this.editedCell = key;
    this.saved = false;
  }

  resetEditedProject() {
    this.editProject = JSON.parse(JSON.stringify(this.project));
  }

  isEditingDisabled(): boolean {
    if (
      (this.project.id && (this.createMode || this.cloneMode ))
    ) {
      return true;
    }
    return false;
  }

  isCompetencySearchResult() {
    if (!this.search || !this.searchTerm) {
      return false;
    }
    return this.project.competencies?.filter((competency) => {
      return !!this.allCompetencies
        .get(competency.competencyId)
        ?.toLowerCase()
        .includes(this.searchTerm?.toLowerCase() ?? "");
    }).length;
  }

  isMemberSearchResult() {
    if (!this.search || !this.searchTerm) {
      return false;
    }
    return this.project.members?.filter((member) =>
      member.employeeName
        ?.toLowerCase()
        .includes(this.searchTerm!.toLowerCase())
    ).length;
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }
}
