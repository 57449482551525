<div
  [ngClass]="{ 'search-sidebar-collapsed': isCollapsed }"
  class="search-sidebar rounded-start-3 shadow"
>
  <div class="arrow-icon ps-2" (click)="toggleSearchSidebar()">
    <fa-icon
      [icon]="isCollapsed ? ['fas', 'chevron-left'] : ['fas', 'chevron-right']"
    ></fa-icon>
  </div>
  <div *ngIf="!isCollapsed" class="search-sidebar-content mt-5">
    <h3 class="search-sidebar-title fw-bold pb-1">
      {{ "search_sidebar.title" | translate }}
    </h3>
    <div class="basic-container">
      <div class="main-container">
        <div class="company-container">
          <div class="title-container">
            <label class="title">{{
              "search_sidebar.companies" | translate
            }}</label>
          </div>
          <app-company-multi-select-dropdown
            (shareCheckedList)="onCompanySelectionChanged($event)"
          ></app-company-multi-select-dropdown>
        </div>
        <div class="competency-container">
          <div class="title-container">
            <label class="title">{{
              "search_sidebar.competencies" | translate
            }}</label>
            <fa-icon
              class="clear-all"
              ngbTooltip="{{ 'search_sidebar.clear_all' | translate }}"
              placement="left"
              container="body"
              [icon]="['fas', 'broom']"
              (click)="clearAllCompetencyConditions()"
            ></fa-icon>
          </div>
          <div class="attribute-container">
            <app-competency-search-input
              *ngFor="let competencyInput of competencyInputs; index as i"
              class="input"
              [competencies]="filteredCompetencies"
              (removedEvent)="removeCompetencyInput(i)"
              (changed)="updateCompetencyAt($event, i)"
            ></app-competency-search-input>
          </div>
        </div>
        <div>
          <button
            class="btn btn-primary add-button"
            (click)="addNewCompetencyInput()"
          >
            {{ "search_sidebar.add_competency" | translate }}
          </button>
        </div>
      </div>
      <div class="action">
        <button
          class="btn btn-primary search-button"
          [disabled]="isSearchButtonDisabled()"
          (click)="searchWithConditions()"
        >
          {{ "search_sidebar.search" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
