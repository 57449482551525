import { Component, Input } from "@angular/core";
import { CompetencyPillCommand } from "src/shared/models/competency-pill-command.model";

@Component({
  selector: "app-competency-pill",
  templateUrl: "./competency-pill.component.html",
  styleUrls: ["./competency-pill.component.scss"],
})
export class CompetencyPillComponent {
  @Input() competency: any;
  @Input() commands: CompetencyPillCommand[] = [];

  constructor() {}

  collectClasses(): string {
    return (
      this.commands
        .map((command) =>
          command.alt && command.alt.isAltActive(this.competency)
            ? command.alt.class?.join(" ")
            : command.class?.join(" ")
        )
        .join(" ") || ""
    );
  }
}
