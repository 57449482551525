import { Component, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  NgbModal,
  NgbToast,
} from "@ng-bootstrap/ng-bootstrap";
import { BasketDTO, BasketService } from "src/shared/api/generated";
import { CreateBasketModalComponent } from "../profile-export/basket/create-basket-modal/create-basket-modal.component";

@Component({
  selector: "app-basket-floating-button",
  templateUrl: "./basket-floating-button.component.html",
  styleUrls: ["./basket-floating-button.component.scss"],
})
export class BasketFloatingButtonComponent {
  @Input() employeeIds: number[] = [];
  @ViewChild("successToast") successToast: NgbToast | undefined;
  showDropdown: boolean = false;
  showSuccessToast: boolean = false;
  allBaskets: BasketDTO[] = [];

  constructor(
    private basketService: BasketService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.refreshBaskets();
  }

  addSelectedEmployeeToBasket(basketId: number) {
    if (!this.employeeIds.length) {
      return;
    }
    this.basketService
      .createProfilesInBasket(basketId, this.employeeIds)
      .subscribe({
        next: () => {
          this.refreshBaskets();
          this.showSuccessToast = true;
        },
      });
  }

  openNewBasketModal() {
    const modalRef = this.modalService.open(CreateBasketModalComponent);
    modalRef.result.then(
      (result) => {
        if (result) {
          this.addSelectedEmployeeToBasket(result.id);
        }
      },
      () => {}
    );
  }

  refreshBaskets() {
    this.basketService
      .getAllBaskets()
      .subscribe({ next: (result) => (this.allBaskets = result) });
  }

  toggleDropdown(): void {
    if (this.employeeIds.length) {
      this.showDropdown = !this.showDropdown;
    } else {
      this.router.navigateByUrl("/profile-export");
    }
  }

  areBasketsNotEmpty() {
    for (const basket of this.allBaskets) {
      if (basket.profiles && basket.profiles.length > 0) {
        return true;
      }
    }
    return false;
  }
}
