import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { CompanyDTO, CompanyService } from "src/shared/api/generated";
import { CompanyMarker } from "src/shared/directives/company-marker";
import { PermissionService } from "src/shared/services/permission.service";

@Component({
  selector: "app-company-separated-display",
  templateUrl: "./company-separated-display.component.html",
  styleUrls: ["./company-separated-display.component.scss"],
})
export class CompanySeparatedDisplayComponent {
  @Input() animation: boolean = true;
  @ContentChildren(CompanyMarker, { read: TemplateRef })
  templates: TemplateRef<any>[];
  @Input() companies: CompanyDTO[];
  @Output() companyAddedEvent = new EventEmitter<void>();

  currentlyEditedCompany: CompanyDTO | null;
  editingState: boolean;
  saved: boolean;

  constructor(
    private companyService: CompanyService,
    public permissionService: PermissionService
  ) {
    this.templates = [];
    this.companies = [];
    this.currentlyEditedCompany = null;
    this.editingState = false;
    this.saved = false;
  }

  add() {
    if (!this.editingState) {
      this.saved = false;
      this.editingState = true;
      this.companies = [...this.companies, { id: undefined, name: "" }];
      this.currentlyEditedCompany = { id: undefined, name: "" };
    }
  }

  setCompanyNameEditable(company: CompanyDTO) {
    this.currentlyEditedCompany = company;
    this.editingState = true;
    this.saved = false;
  }

  saveCompany(id: number | undefined, name: string) {
    if (this.saved) {
      return;
    }
    if (!id && name === "") {
      this.companies = this.companies.filter((company) => company.id);
    } else if (id && name === "") {
      name = this.companies.filter((company) => company.id === id).at(0)!.name;
    }

    if (name !== "") {
      this.companyService.createCompany({ id, name }).subscribe((company) => {
        this.companyAddedEvent.emit();
        this.saved = true;
      });
    }
    this.editingState = false;
    this.currentlyEditedCompany = null;
  }

  cancelEditing() {
    this.companies = this.companies.filter((company) => company.id);
    this.currentlyEditedCompany = null;
    this.editingState = false;
  }

  getActiveTab(): number {
    if (this.currentlyEditedCompany === null) {
      return 0;
    }
    return this.companies.findIndex(
      (x, i) => x.name === this.currentlyEditedCompany!.name
    );
  }

  getTemplate(i: number) {
    return Array.from(this.templates)[i];
  }
}
