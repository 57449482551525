<div class="modal-header">
  <div>
    <h4 class="modal-title">
      {{ "plan.modify_modal.title" | translate }}
    </h4>
    <div class="label-container">
      <label class="name-label">{{ employeeName }}</label>
    </div>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body main-container">
  <div class="form-row">
    <label>{{ "plan.modify_modal.project_name" | translate }}</label>
    <label class="project-name-label">{{ projectName }}</label>
  </div>
  <div class="form-row">
    <label>{{ "plan.modify_modal.start" | translate }}</label>
    <input [(ngModel)]="plan.from" type="date" />
  </div>
  <div class="form-row">
    <label>{{ "plan.modify_modal.end" | translate }}</label>
    <input [(ngModel)]="plan.to" type="date" />
  </div>
  <div class="form-row">
    <label>{{ "plan.modify_modal.percentage" | translate }}</label>
    <div class="percentage-input">
      <input
        [(ngModel)]="plan.percentage"
        type="number"
        min="0"
        max="100"
        class="percentage-number-input"
      />
      <input
        [(ngModel)]="plan.percentage"
        type="range"
        min="0"
        max="100"
        step="5"
      />
    </div>
  </div>
  <div class="form-row">
    <label>{{ "plan.modify_modal.description" | translate }}</label>
    <div class="w-50">
      <textarea
        class="px-2"
        rows="4"
        placeholder="{{ 'plan.modify_modal.placeholder' | translate }}"
        [(ngModel)]="plan.description"
      ></textarea>
    </div>
  </div>
  <div class="form-row">
    <label>{{ "plan.modify_modal.competency" | translate }}</label>
    <div class="w-50">
      <app-competency-pill-container
        [competencies]="competencies"
        [allCompetencies]="allCompetencies"
        [employeeCompetencies]="employeeCompetencies"
        [commands]="commands"
        [isAddPillButtonActive]="true"
        (newCompetencyEvent)="checkAndAddNewCompetency($event)"
      ></app-competency-pill-container>
    </div>
  </div>
</div>
<div *ngIf="error" class="error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">{{
    errorMessage
  }}</ngb-alert>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary cancel-button"
    (click)="activeModal.close(false)"
  >
    {{ "plan.modify_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary ok-button"
    [disabled]="isDisabled()"
    (click)="savePlan()"
  >
    {{ "plan.modify_modal.save" | translate }}
  </button>
</div>
