import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  LookingForProjectDTO,
  LookingForProjectService,
  UserSelectDTO,
} from "src/shared/api/generated";
import { DisplayAndEditEmployeeCompetencyDto } from "src/shared/models/display-and-edit-employee-competency.model";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { isDeepEqual } from "src/shared/util/is-deep-equal";
import { Subscription } from "rxjs";
import { convertEmployeeCompetencies } from "src/shared/util/convert-employee-competencies";
import { ReadOnlyProfileModalComponent } from "../../read-only-profile-modal/read-only-profile-modal.component";
import { LookingForCommentModalComponent } from "../looking-for-comment-modal/looking-for-comment-modal.component";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";

@Component({
  selector: "[app-tr-looking-for-project]",
  templateUrl: "./tr-looking-for-project.component.html",
  styleUrls: ["./tr-looking-for-project.component.scss"],
})
export class TrLookingForProjectComponent implements OnInit, OnDestroy {
  @Input() lookingForProject: LookingForProjectDTO = {} as LookingForProjectDTO;
  @Input() users: UserSelectDTO[] = [];
  @Input() searchTerm: string | undefined = "";
  @Input() search: boolean = false;
  @Input() allCompetencies: Map<number, string> = new Map();
  @Output() lookingForProjectSaved = new EventEmitter<void>();

  editLookingForProject: LookingForProjectDTO = {} as LookingForProjectDTO;
  selectedCellEmployeeId: number | null = null;
  editedCell: string = "";
  averageOfTop3Competencies: number = 0;
  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyManagerService: CompetencyManagerService,
    private lookingForProjectService: LookingForProjectService,
    private modalService: NgbModal,
    private confirmationModalService: ConfirmationModalService
  ) {}

  ngOnInit(): void {
    this.editLookingForProject = JSON.parse(
      JSON.stringify(this.lookingForProject)
    );
    this.averageOfTop3Competencies =
      Math.round(this.lookingForProject.averageOfTop3Competencies! * 100) / 100;
    this.subscriptions.add(
      this.competencyManagerService.employeeCompetency.subscribe(
        (data: DisplayAndEditEmployeeCompetencyDto) => {
          this.selectedCellEmployeeId = data.employeeId;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setEditStateFalse() {
    this.editedCell = "";
  }

  setEditStateTrue(key: string) {
    this.editedCell = key;
  }

  saveLookingForProject() {
    if (isDeepEqual(this.editLookingForProject, this.lookingForProject)) {
      return;
    }

    this.lookingForProjectService
      .updateLookingForProject({
        id: this.lookingForProject.id,
        assigneeId: this.editLookingForProject.assigneeId,
        comment: this.editLookingForProject.comment,
        status: this.editLookingForProject.status,
        freeFrom: this.editLookingForProject.freeFrom,
      })
      .subscribe({
        next: (_) => {
          this.lookingForProjectSaved.emit();
        },
        error: (error) => {
          if (
            error.error.message ===
            "There is already an active Looking For Project for the given employee"
          ) {
            this.editLookingForProject = JSON.parse(
              JSON.stringify(this.lookingForProject)
            );
            this.confirmationModalService.openConfirmationModal(
              "home.looking_for_project.error.title",
              "home.looking_for_project.error.already_has_active_entry",
              true
            );
          }
        },
      });
  }

  selectCompetencyCell($event: MouseEvent) {
    this.inputClicked($event);
    if (this.lookingForProject.employeeId === this.selectedCellEmployeeId) {
      this.competencyManagerService.clearEmployeeCompetency();
      return;
    }
    this.competencyManagerService.setEmployeeCompetency(
      true,
      this.lookingForProject.employeeId,
      this.lookingForProject.employeeName,
      this.lookingForProject.competencies
    );
  }

  isSearchResult(): boolean {
    if (!this.search || !this.searchTerm) {
      return false;
    }
    return (
      convertEmployeeCompetencies(this.lookingForProject.competencies).filter(
        (competency) => {
          return !!this.allCompetencies
            .get(competency.id)
            ?.toLowerCase()
            .includes(this.searchTerm?.toLowerCase() ?? "");
        }
      ).length !== 0
    );
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }

  openReadOnlyProfile(id: number) {
    const modalRef = this.modalService.open(ReadOnlyProfileModalComponent);
    modalRef.componentInstance.employeeId = id;
  }

  openCommentEditModal($event: MouseEvent) {
    this.inputClicked($event);
    const modalRef = this.modalService.open(LookingForCommentModalComponent);
    modalRef.componentInstance.lookingForEntity = { ...this.lookingForProject };
    modalRef.componentInstance.entityName = this.lookingForProject.employeeName;
    modalRef.componentInstance.mode = "looking-for-project";
    modalRef.result.then(
      (result) => {
        if (result) {
          this.lookingForProjectSaved.emit();
        }
      },
      () => {}
    );
  }
}
