import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationModalComponent } from "src/app/application/confirmation-modal/confirmation-modal.component";

@Injectable({ providedIn: "root" })
export class ConfirmationModalService {
  constructor(
    public modalService: NgbModal,
    public translate: TranslateService
  ) {}

  openConfirmationModal(titleKey: string, messageKey: string, error: boolean = false) {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.title = this.translate.instant(titleKey);
    modalRef.componentInstance.message = this.translate.instant(messageKey);
    modalRef.componentInstance.error = error;

    return modalRef.result;
  }
}
