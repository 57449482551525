import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BasketDTO, BasketService } from "src/shared/api/generated";

@Component({
  selector: "app-create-basket-modal",
  templateUrl: "./create-basket-modal.component.html",
  styleUrls: ["./create-basket-modal.component.scss"],
})
export class CreateBasketModalComponent {
  basketToAdd: BasketDTO = {} as BasketDTO;

  constructor(
    public activeModal: NgbActiveModal,
    private basketService: BasketService
  ) {}

  saveCompetency() {
    this.basketToAdd.name = this.basketToAdd.name.trim();
    this.basketService.createBasket(this.basketToAdd).subscribe({
      next: (newBasket) => {
        this.activeModal.close(newBasket);
      },
    });
  }

  isCreateDisabled() {
    return this.basketToAdd.name === "";
  }
}
