import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from "@ng-bootstrap/ng-bootstrap";
import {
  Observable,
  OperatorFunction,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
} from "rxjs";
import { CompetencyCondition, CompetencyDTO } from "src/shared/api/generated";

@Component({
  selector: "app-competency-search-input",
  templateUrl: "./competency-search-input.component.html",
  styleUrls: ["./competency-search-input.component.scss"],
})
export class CompetencySearchInputComponent {
  @Input() competencies: CompetencyDTO[] = [];
  @Output() removedEvent = new EventEmitter<void>();
  @Output() changed = new EventEmitter<CompetencyCondition>();

  selectedCompetency = new FormControl<CompetencyDTO>({} as CompetencyDTO);
  @ViewChild("competencyInstance", { static: true })
  competencyInstance: NgbTypeahead = {} as NgbTypeahead;
  competencyFocus$ = new Subject<string>();
  competencyClick$ = new Subject<string>();
  lowerBound: number = 1;
  upperBound: number = 5;
  boundError: boolean = false;

  formatter = (x: CompetencyDTO) => x.name;

  searchCompetency: OperatorFunction<string, CompetencyDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.competencyClick$.pipe(
      filter(() => !this.competencyInstance.isPopupOpen())
    );
    const inputFocus$ = this.competencyFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : this.competencies.filter((v) =>
              v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )
    );
  };

  delete() {
    this.removedEvent.emit();
  }

  selectChanged(item: NgbTypeaheadSelectItemEvent) {
    this.changed.emit({
      competencyId: item.item.id!,
      lowerBound: this.lowerBound,
      upperBound: this.upperBound,
    });
  }

  inputChanged() {
    this.boundError =
      this.lowerBound > this.upperBound ||
      this.lowerBound < 1 ||
      this.upperBound > 5;
    this.changed.emit({
      competencyId: this.selectedCompetency.value?.id!,
      lowerBound: this.lowerBound,
      upperBound: this.upperBound,
    });
  }

  checkEmpty() {
    if (!this.selectedCompetency.value) {
      this.changed.emit({
        competencyId: -1,
        lowerBound: this.lowerBound,
        upperBound: this.upperBound,
      });
    }
  }
}
