<div class="main-container">
  <label class="title-label">{{
    "employee.education_modal.title" | translate
  }}</label>
  <label class="name-label text-overflow-dotted">{{ employeeName }}</label>

  <div class="content">
    <app-table-education
      [employeeId]="employeeId"
      (educationSaved)="onEducationSaved()"
      (educationSaveFailed)="onEducationSaveFailed($event)"
    ></app-table-education>
  </div>
  <div *ngIf="error" class="error">
    <ngb-alert class="alert" type="danger" (closed)="closeAlert()">{{
      errorMessage
    }}</ngb-alert>
  </div>

  <div class="actions">
    <button
      type="button"
      class="btn btn-danger close-button"
      (click)="activeModal.close(educationModified)"
    >
      {{ "employee.education_modal.close" | translate }}
    </button>
  </div>
</div>
