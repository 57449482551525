import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  Observable,
  OperatorFunction,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
} from "rxjs";
import {
  LookingForResourceCreateDTO,
  LookingForResourceService,
  ProjectSelectDTO,
  ProjectService,
  UserSelectDTO,
} from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-create-looking-for-resource",
  templateUrl: "./create-looking-for-resource.component.html",
  styleUrls: ["./create-looking-for-resource.component.scss"],
})
export class CreateLookingForResourceComponent implements OnInit {
  projects: ProjectSelectDTO[] = [];
  @Input() users: UserSelectDTO[] = [];
  newLookingForResource: LookingForResourceCreateDTO = {
    status: "WAITING",
    assigneeId: -1,
    projectId: -1,
    requestStart: "",
    requestEnd: "",
    fte: 0,
  };
  selectedProject: ProjectSelectDTO = { id: -1, name: "" };
  selectedAssignee: UserSelectDTO = { id: -1, name: "" };
  error: boolean = false;
  errorMessage: string = "";
  @ViewChild("projectInstance", { static: true })
  projectInstance: NgbTypeahead = {} as NgbTypeahead;
  @ViewChild("assigneeInstance", { static: true })
  assigneeInstance: NgbTypeahead = {} as NgbTypeahead;
  projectFocus$ = new Subject<string>();
  assigneeFocus$ = new Subject<string>();
  projectClick$ = new Subject<string>();
  assigneeClick$ = new Subject<string>();

  constructor(
    public activeModal: NgbActiveModal,
    private lookingForResourceService: LookingForResourceService,
    private translate: TranslateService,
    private projectService: ProjectService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getProjects();
  }

  getProjects() {
    this.projectService.getAllProjectSelectDtos()
    .pipe(showLoadingSpinner(this.spinnerService)).subscribe((allProjects) => {
      this.projects = allProjects;
    });
  }

  saveNewProject() {
    if (this.isDisabled()) {
      return;
    }
    this.newLookingForResource.projectId = this.selectedProject.id!;
    this.newLookingForResource.assigneeId = this.selectedAssignee.id!;
    this.lookingForResourceService
      .createLookingForResource(this.newLookingForResource)
      .subscribe({
        next: (_) => {
          this.activeModal.close(true);
        },
        error: (error) => {
          if (
            error.error.message ===
            "There is already an active Looking For Resource for the given project"
          )
            this.errorMessage = this.translate.instant(
              "home.looking_for_resources.create_modal.error.already_has_active_entry"
            );
          this.error = true;
        },
      });
  }

  isDisabled(): boolean {
    return (
      this.selectedProject?.id! < 0 ||
      this.selectedAssignee?.id! < 0 ||
      this.newLookingForResource.fte === null
    );
  }

  closeAlert() {
    this.error = false;
  }

  searchProject: OperatorFunction<string, ProjectSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.projectClick$.pipe(
      filter(() => !this.projectInstance.isPopupOpen())
    );
    const inputFocus$ = this.projectFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : this.projects.filter((v) => new RegExp(term, "miu").test(v.name!))
        ).slice(0, 10)
      )
    );
  };

  searchAssignee: OperatorFunction<string, UserSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.assigneeClick$.pipe(
      filter(() => !this.assigneeInstance.isPopupOpen())
    );
    const inputFocus$ = this.assigneeFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? this.users
          : this.users.filter((v) => new RegExp(term, "miu").test(v.name!))
        )
          .sort((a, b) => a.name!.localeCompare(b.name!))
          .slice(0, 10)
      )
    );
  };

  formatter = (x: ProjectSelectDTO | UserSelectDTO) => x.name!;
}
