<div
  class="container-fluid login-container w-100 h-100 d-flex flex-row align-items-center"
>
  <div
    class="container login-panel d-flex flex-column p-4 justify-content-around align-items-center shadow-lg"
  >
    <div class="row p-3">
      <div class="col">
        <h1 class="text-center">{{ "login.title" | translate }}</h1>
      </div>
    </div>
    <div class="image-container">
      <img
        src="..\..\assets\img\squirrel_standing_tie.png"
      />
    </div>
    <div *ngIf="badAuthentication" class="error mt-3">
      <ngb-alert class="alert" type="danger" (closed)="badAuthentication = false">{{
        "authentication.answer" | translate
      }}</ngb-alert>
    </div>
    <div class="row p-3">
      <div class="col button-container">
        <button
          class="btn btn-primary btn-lg login-button"
          (click)="signInWithGoogle()"
        >
          <fa-icon [icon]="['fab', 'google']" size="lg"></fa-icon>
          {{ "login.sign_in_with_google" | translate }}
        </button>
        <button
          class="btn btn-primary btn-lg login-button"
          (click)="signInWithMicrosft()"
        >
          <fa-icon [icon]="['fab', 'microsoft']" size="lg"></fa-icon>
          {{ "login.sign_in_with_microsoft" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
