/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LatestImportStats } from './latestImportStats';


export interface CheckImportStatusResponse { 
    currentStatus?: CheckImportStatusResponse.CurrentStatusEnum;
    latestImportStats?: LatestImportStats;
}
export namespace CheckImportStatusResponse {
    export type CurrentStatusEnum = 'SUCCESS' | 'FAILURE' | 'NOT_STARTED' | 'RUNNING';
    export const CurrentStatusEnum = {
        Success: 'SUCCESS' as CurrentStatusEnum,
        Failure: 'FAILURE' as CurrentStatusEnum,
        NotStarted: 'NOT_STARTED' as CurrentStatusEnum,
        Running: 'RUNNING' as CurrentStatusEnum
    };
}


