<td (dblclick)="setEditStateTrue('code')">
  <input
    *ngIf="this.editedCell === 'code' && this.education.id"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="saveEducation()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="saveEducation()"
    [(ngModel)]="editedEducation.code"
    (click)="inputClicked($event)"
  />
  <input *ngIf="!this.education.id" type="text" [(ngModel)]="education.code" />
  <label *ngIf="this.editedCell !== 'code' && this.education.id">{{
    education.code
  }}</label>
</td>
<td (dblclick)="setEditStateTrue('name')">
  <input
    *ngIf="this.editedCell === 'name' && this.education.id"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="saveEducation()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="saveEducation()"
    [(ngModel)]="editedEducation.name"
    (click)="inputClicked($event)"
  />
  <input *ngIf="!this.education.id" type="text" [(ngModel)]="education.name" />
  <label *ngIf="this.editedCell !== 'name' && this.education.id">{{
    education.name
  }}</label>
</td>
<td (dblclick)="setEditStateTrue('start')">
  <input
    *ngIf="this.editedCell === 'start' && this.education.id"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="saveEducation()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="saveEducation()"
    [disabled]="isEditingDisabled()"
    [(ngModel)]="editedEducation.start"
    (click)="inputClicked($event)"
  />
  <input *ngIf="!this.education.id" type="date" [(ngModel)]="education.start" />
  <label *ngIf="this.editedCell !== 'start' && this.education.id">{{
    education.start
  }}</label>
</td>
<td (dblclick)="setEditStateTrue('end')">
  <input
    *ngIf="this.editedCell === 'end' && this.education.id"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="saveEducation()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="saveEducation()"
    [disabled]="isEditingDisabled()"
    [(ngModel)]="editedEducation.end"
    (click)="inputClicked($event)"
  />
  <input *ngIf="!this.education.id" type="date" [(ngModel)]="education.end" />
  <label *ngIf="this.editedCell !== 'end' && this.education.id">{{
    education.end
  }}</label>
</td>
<td (dblclick)="setEditStateTrue('valid_until')">
  <input
    *ngIf="this.editedCell === 'valid_until' && this.education.id"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="saveEducation()"
    (keydown.escape)="setEditStateFalse()"
    [disabled]="isEditingDisabled()"
    (focusout)="saveEducation()"
    [(ngModel)]="editedEducation.validUntil"
    (click)="inputClicked($event)"
  />
  <input
    *ngIf="!this.education.id"
    type="date"
    [(ngModel)]="education.validUntil"
  />
  <label *ngIf="this.editedCell !== 'valid_until' && this.education.id">{{
    education.validUntil
  }}</label>
</td>
<td class="text-center">
  <fa-icon
    class="delete-icon"
    [icon]="['fas', 'trash']"
    (click)="deleteEducation()"
    [ngClass]="{ 'disabled-icon': createMode }"
  ></fa-icon>
</td>
