import { Component } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, Subscription, interval, switchMap } from "rxjs";
import {
  CheckImportStatusResponse,
  CompanyDTO,
  CompanyService,
  ImportCompetencyService,
  LatestImportStats,
} from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-competency-import",
  templateUrl: "./competency-import.component.html",
  styleUrls: ["./competency-import.component.scss"],
})
export class CompetencyImportComponent {
  selectedFile: File | null = null;
  companies: CompanyDTO[] = [];
  selectedCompanyId: number | undefined = undefined;
  errorWhileImport: boolean = false;
  successfulImport: boolean = false;
  importInProgress: boolean = false;
  lastImportStats: LatestImportStats | undefined = undefined;
  importStatusEnum = LatestImportStats.ImportStatusEnum;

  private statusSubscription: Subscription | undefined;

  constructor(
    private importCompetencyService: ImportCompetencyService,
    private companyService: CompanyService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.companyService.getVisibleCompanies()
    .pipe(showLoadingSpinner(this.spinnerService))
    .subscribe({
      next: (companies) => {
        this.companies = companies.sort((a, b) => a.name.localeCompare(b.name));
        this.selectedCompanyId = companies.at(0)?.id;
      },
    });
    this.checkImportStatusEverySecond();
  }

  ngOnDestroy() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0] as File;
  }

  uploadFile() {
    if (this.selectedFile && this.selectedCompanyId !== undefined) {
      this.importCompetencyService
        .importCompetenciesFromExcel(this.selectedCompanyId, this.selectedFile)
        .pipe(showLoadingSpinner(this.spinnerService))
        .subscribe({
          next: (message) => {
            this.checkImportStatusEverySecond();
          },
        });
    }
  }

  checkImportStatus(): Observable<CheckImportStatusResponse> {
    return interval(1000).pipe(
      switchMap(() => this.importCompetencyService.checkImportStatus())
    );
  }

  checkImportStatusEverySecond() {
    if (!this.statusSubscription || this.statusSubscription.closed) {
      this.statusSubscription = this.checkImportStatus().subscribe({
        next: (status) => {
          this.lastImportStats = status.latestImportStats;
          switch (status.currentStatus) {
            case CheckImportStatusResponse.CurrentStatusEnum.Success:
              this.importInProgress = false;
              this.errorWhileImport = false;
              this.successfulImport = true;
              this.statusSubscription?.unsubscribe();
              break;
            case CheckImportStatusResponse.CurrentStatusEnum.Failure:
              this.importInProgress = false;
              this.successfulImport = false;
              this.errorWhileImport = true;
              this.statusSubscription?.unsubscribe();
              break;
            case CheckImportStatusResponse.CurrentStatusEnum.Running:
              this.importInProgress = true;
              this.errorWhileImport = false;
              this.successfulImport = false;
              break;
            case CheckImportStatusResponse.CurrentStatusEnum.NotStarted:
              this.statusSubscription?.unsubscribe();
              break;
          }
        },
      });
    }
  }

  getFormattedTimeOfImport(time: string | undefined) {
    if (time) {
      return new Date(time).toLocaleString("hu");
    }
    return null;
  }
}
