/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompetencyDTO } from './competencyDTO';


export interface ProfileCompetencyDTO { 
    id?: number;
    competency?: CompetencyDTO;
    visible?: boolean;
    level?: number;
    showCompetencyAs?: ProfileCompetencyDTO.ShowCompetencyAsEnum;
}
export namespace ProfileCompetencyDTO {
    export type ShowCompetencyAsEnum = 'BAR' | 'NUMBER' | 'NAME_ONLY';
    export const ShowCompetencyAsEnum = {
        Bar: 'BAR' as ShowCompetencyAsEnum,
        Number: 'NUMBER' as ShowCompetencyAsEnum,
        NameOnly: 'NAME_ONLY' as ShowCompetencyAsEnum
    };
}


