import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import {
  LookingForResourceDTO,
  LookingForResourceService,
  UserSelectDTO,
} from "src/shared/api/generated";
import { DisplayAndEditProjectCompetencyDto } from "src/shared/models/display-and-edit-project-competency.model";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { isDeepEqual } from "src/shared/util/is-deep-equal";
import { ProjectMembersModalComponent } from "../../project-members-modal/project-members-modal.component";
import { Subscription } from "rxjs";
import { LookingForCommentModalComponent } from "../looking-for-comment-modal/looking-for-comment-modal.component";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";

@Component({
  selector: "[app-tr-looking-for-resource]",
  templateUrl: "./tr-looking-for-resource.component.html",
  styleUrls: ["./tr-looking-for-resource.component.scss"],
})
export class TrLookingForResourceComponent implements OnInit, OnDestroy {
  @Input() lookingForResource: LookingForResourceDTO =
    {} as LookingForResourceDTO;
  @Input() users: UserSelectDTO[] = [];
  @Input() searchTerm: string | undefined = "";
  @Input() search: boolean = false;
  @Input() allCompetencies: Map<number, string> = new Map();
  @Output() lookingForResourceSaved = new EventEmitter<void>();
  editedCell = "";

  editLookingForResource: LookingForResourceDTO = {} as LookingForResourceDTO;
  projectStateLabel: string = "";
  selectedCellProjectId: number | null = null;
  selectedMemberCellProjectId: number | null = null;
  subscriptions: Subscription = new Subscription();

  constructor(
    private translate: TranslateService,
    private competencyManagerService: CompetencyManagerService,
    private lookingForResourceService: LookingForResourceService,
    private modalService: NgbModal,
    private confirmationModalService: ConfirmationModalService
  ) {}

  ngOnInit(): void {
    this.editLookingForResource = JSON.parse(
      JSON.stringify(this.lookingForResource)
    );
    this.projectStateLabel = this.translate.instant(
      "home.looking_for_resources.state." +
        `${this.lookingForResource.projectState}`
    );
    this.subscriptions.add(
      this.competencyManagerService.projectCompetency.subscribe(
        (data: DisplayAndEditProjectCompetencyDto) => {
          this.selectedCellProjectId = data.projectId;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  saveLookingForResource() {
    if (isDeepEqual(this.editLookingForResource, this.lookingForResource)) {
      this.setEditStateFalse();
      return;
    }

    if (this.editLookingForResource.fte === null) {
      this.setEditStateFalse();
      return;
    }

    this.lookingForResourceService
      .updateLookingForResource({
        id: this.lookingForResource.id,
        assigneeId: this.editLookingForResource.assigneeId,
        status: this.editLookingForResource.status,
        comment: this.editLookingForResource.comment,
        requestStart: this.editLookingForResource.requestStart,
        requestEnd: this.editLookingForResource.requestEnd,
        fte: this.editLookingForResource.fte,
      })
      .subscribe({
        next: (_) => {
          this.lookingForResourceSaved.emit();
        },
        error: (error) => {
          if (
            error.error.message ===
            "There is already an active Looking For Resource for the given project"
          ) {
            this.editLookingForResource = JSON.parse(
              JSON.stringify(this.lookingForResource)
            );
            this.confirmationModalService.openConfirmationModal(
              "home.looking_for_resources.error.title",
              "home.looking_for_resources.error.already_has_active_entry",
              true
            );
          }
        },
      });
    this.setEditStateFalse();
  }

  selectCompetencyCell($event: MouseEvent) {
    this.inputClicked($event);
    if (!this.lookingForResource.projectId) {
      return;
    }
    if (this.lookingForResource.projectId === this.selectedCellProjectId) {
      this.competencyManagerService.clearProjectCompetency();
      return;
    }
    this.competencyManagerService.setProjectCompetency(
      true,
      this.lookingForResource.projectId,
      this.lookingForResource.projectName ?? "",
      this.lookingForResource.competencies ?? []
    );
  }

  selectMemberCell($event: MouseEvent) {
    this.inputClicked($event);
    if (!this.lookingForResource.id) {
      return;
    }
    const modalRef = this.modalService.open(ProjectMembersModalComponent, {
      size: "lg",
      keyboard: false,
    });
    modalRef.componentInstance.projectName =
      this.lookingForResource.projectName;
    modalRef.componentInstance.projectMembers = this.lookingForResource.members;
    modalRef.componentInstance.allCompetencies = this.allCompetencies;
    this.selectedMemberCellProjectId = this.lookingForResource.projectId;
    modalRef.closed.subscribe(() => (this.selectedMemberCellProjectId = null));
    modalRef.dismissed.subscribe(
      () => (this.selectedMemberCellProjectId = null)
    );
  }

  isMemberSearchResult() {
    if (!this.search || !this.searchTerm) {
      return false;
    }
    return this.lookingForResource.members.filter((member) =>
      member.employeeName
        ?.toLowerCase()
        .includes(this.searchTerm!.toLowerCase())
    ).length;
  }

  isCompetencySearchResult() {
    if (!this.search || !this.searchTerm) {
      return false;
    }
    return (
      (
        this.lookingForResource.competencies?.filter((competency) => {
          return !!this.allCompetencies
            .get(competency.competencyId)
            ?.toLowerCase()
            .includes(this.searchTerm?.toLowerCase() ?? "");
        }) ?? []
      ).length > 0
    );
  }

  setEditStateFalse() {
    this.editedCell = "";
  }

  setEditStateTrue(key: string) {
    this.editedCell = key;
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }

  openCommentEditModal($event: MouseEvent) {
    this.inputClicked($event);
    const modalRef = this.modalService.open(LookingForCommentModalComponent);
    modalRef.componentInstance.lookingForEntity = {
      ...this.lookingForResource,
    };
    modalRef.componentInstance.entityName = this.lookingForResource.projectName;
    modalRef.componentInstance.mode = "looking-for-resource";
    modalRef.result.then(
      (result) => {
        if (result) {
          this.lookingForResourceSaved.emit();
        }
      },
      () => {}
    );
  }
}
