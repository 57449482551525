<div class="d-flex w-100 h-100 base-container">
  <div class="content mw-100 mh-100 d-flex flex-grow-1 pt-3 flex-column">
    <div class="title-container">
      <img src="..\..\assets\img\squirrel_standing_tie.png" class="title-squirrel-image">
      <h1 class="title">{{ "home.title" | translate }}</h1>
    </div>
    <div class="flex-grow-1 d-flex flex-column pb-3 tabs">
      <div
        class="d-flex flex-column h-100 shadow rounded-3 separated-display-container"
      >
        <ul
          ngbNav
          #nav="ngbNav"
          class="nav-tabs rounded-top-3 border-bottom-0"
          [destroyOnHide]="true"
        >
          <li [ngbNavItem]="0">
            <button ngbNavLink class="nav-link border-bottom-0">
              <label class="nav-link-label text-overflow-dotted">{{
                "home.looking_for_resources.label" | translate
              }}</label>
            </button>
            <ng-template ngbNavContent
              ><app-table-looking-for-resource
                class="looking-for-table h-100"
              ></app-table-looking-for-resource
            ></ng-template>
          </li>
          <li [ngbNavItem]="1">
            <button ngbNavLink class="nav-link border-bottom-0">
              <label class="nav-link-label text-overflow-dotted">{{
                "home.looking_for_project.label" | translate
              }}</label>
            </button>
            <ng-template ngbNavContent>
              <app-table-looking-for-project
                class="looking-for-table h-100"
              ></app-table-looking-for-project>
            </ng-template>
          </li>
        </ul>
        <div
          [ngbNavOutlet]="nav"
          class="mh-100 p-3 rounded-bottom-3 flex-grow-1 tab-main"
        ></div>
      </div>
    </div>
  </div>
  <div class="competency">
    <app-competency>
      <app-project-competency
        *ngIf="nav.activeId === 0"
        class="competencies"
      ></app-project-competency>
      <app-employee-competency
        class="competencies"
        *ngIf="nav.activeId === 1"
      ></app-employee-competency>
    </app-competency>
  </div>
</div>

