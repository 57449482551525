<nav class="sidebar">
  <ul class="nav flex-column nav-container">
    <li class="nav-item">
      <div
        class="nav-icon"
      >
        <a class="nav-link">
          <fa-icon class="profile" [icon]="['fas', 'circle-user']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.home' | translate }}"
      >
        <a class="nav-link" routerLink="/home" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'house-chimney']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.plan' | translate }}"
      >
        <a class="nav-link" routerLink="/plan" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'calendar-days']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.employees' | translate }}"
      >
        <a class="nav-link" routerLink="/employee" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'user-tie']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.projects' | translate }}"
      >
        <a class="nav-link" routerLink="/project" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'bars-staggered']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.smart-search' | translate }}"
      >
        <a
          class="nav-link"
          routerLink="/smart-search"
          routerLinkActive="active"
        >
          <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.profile_export' | translate }}"
      >
        <a
          class="nav-link"
          routerLink="/profile-export"
          routerLinkActive="active"
        >
          <fa-icon [icon]="['fas', 'address-card']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isAdmin">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.settings' | translate }}"
      >
        <a class="nav-link" routerLink="/settings" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'gear']"></fa-icon>
        </a>
      </div>
    </li>
  </ul>
  <div class="actions">
    <fa-icon
      class="logout"
      ngbTooltip="{{ 'sidebar.logout' | translate }}"
      [icon]="['fas', 'arrow-right-from-bracket']"
      (click)="logout()"
    ></fa-icon>
  </div>
</nav>
