<div class="main-container">
  <div ngbAccordion>
    <div ngbAccordionItem *ngFor="let basket of allBaskets">
      <div
        ngbAccordionHeader
        class="accordion-button custom-header align-items-center"
      >
        <fa-icon
          class="delete-basket-icon"
          (click)="onDeleteBasket(basket.id!)"
          [icon]="['fas', 'trash']"
        ></fa-icon>
        <div
          *ngIf="basket.id !== editedBasket.id"
          class="basket-name ms-4"
          (dblclick)="onStartEditing(basket)"
        >
          {{ basket.name }}
        </div>
        <input
          *ngIf="basket.id === editedBasket.id"
          type="text"
          appPrefixFocusAndSelect
          class="form-control edit-basket-input ms-4 me-1"
          [(ngModel)]="editedBasket.name"
          (keydown.enter)="onSaveEditing()"
          (keydown.escape)="onCancelEditing()"
          (click)="$event.stopPropagation()"
        />
        <button
          ngbAccordionToggle
          class="accordion-button open-button ms-auto"
        ></button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="ms-5">
          <app-table-basket-employee
            [basketId]="basket.id"
            [profiles]="basket.profiles!"
            [profileExportQueue]="profileExportQueue"
            (profileRemoved)="onProfileRemovedFromBasket($event)"
            (profileUpdatedInBasket)="onProfileUpdated($event)"
            class="table-basket-employee"
          ></app-table-basket-employee>
        </div>
      </div>
    </div>
  </div>
  <button
    type="button"
    class="btn btn-primary new-basket-button mt-5 ms-3"
    (click)="openNewBasketModal()"
  >
    {{ "basket.new_basket" | translate }}
  </button>
</div>

