import { Component } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CompanyService, CompanyDTO } from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.scss"],
})
export class PlanComponent {
  visibleCompanies: CompanyDTO[] = [];

  constructor(
    private companyService: CompanyService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.refreshCompanies();
  }

  refreshCompanies() {
    this.companyService.getVisibleCompanies()
    .pipe(showLoadingSpinner(this.spinnerService))
    .subscribe((companies) => {
      this.visibleCompanies = [...companies].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    });
  }
}
