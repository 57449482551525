import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  BasketDTO,
  BasketService,
  CompetencyDTO,
  ProfileDTO,
  ProfileService,
} from "src/shared/api/generated";
import { ModifyProfileModalComponent } from "../modify-profile-modal/modify-profile-modal.component";

@Component({
  selector: "[app-tr-basket-employee]",
  templateUrl: "./tr-basket-employee.component.html",
  styleUrls: ["./tr-basket-employee.component.scss"],
})
export class TrBasketEmployeeComponent {
  @Input() profile: ProfileDTO | null = null;
  @Input() basketId: number | undefined;
  @Input() profileExportQueue: ProfileDTO[] = [];
  @Output() profileRemoved = new EventEmitter<BasketDTO>();
  @Output() profileUpdated = new EventEmitter<ProfileDTO>();

  constructor(
    private basketService: BasketService,
    private profileService: ProfileService,
    public modalService: NgbModal
  ) {}

  removeEmployee() {
    if (this.isModifyingDisabled()) {
      return;
    }
    this.basketService
      .removeProfileFromBasket(this.basketId!, this.profile?.id!)
      .subscribe({
        next: (basket) => {
          this.profileRemoved.emit(basket);
        },
      });
  }

  saveProfile() {
    this.profileService.updateProfile(this.profile!).subscribe({
      next: (profile) => this.profileUpdated.emit(profile),
    });
  }

  isModifyingDisabled(): boolean {
    return (
      this.profileExportQueue.find((p) => p.id === this.profile?.id) !==
      undefined
    );
  }

  openModifyProfileModal() {
    if (this.isModifyingDisabled()) {
      return;
    }
    const modalRef = this.modalService.open(ModifyProfileModalComponent, {
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.profile = JSON.parse(
      JSON.stringify(this.profile)
    );
    modalRef.result.then(
      (result) => {
        if (result) {
          this.profile = result;
          this.profileUpdated.emit(result);
        }
      },
      () => {}
    );
  }
}
