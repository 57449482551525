import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ConfigurationService } from "src/shared/services/configuration.service";
import { PermissionService } from "src/shared/services/permission.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent {

  isAdmin: boolean = false;
  isNotSales: boolean = true;

  constructor(
    private configService: ConfigurationService,
    public permissionService: PermissionService,
    private router: Router
  ) {
    this.isAdmin = permissionService.checkAdmin();
    this.isNotSales = permissionService.checkNotSalesUser();
  }

  logout() {
    this.configService.clearToken();
    this.router.navigateByUrl("/login");
  }
}
