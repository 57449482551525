/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyDTO } from './companyDTO';


export interface UserDTO { 
    id?: number;
    email?: string;
    name?: string;
    active?: boolean;
    company?: CompanyDTO;
    admin?: boolean;
    global?: boolean;
    sales?: boolean;
    visibleCompanies?: Array<CompanyDTO>;
}

