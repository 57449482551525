<div class="app-container" cdkDropListGroup>
  <div class="row m-0 px-0 h-100">
    <div class="col sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
