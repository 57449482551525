import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  CompanyDTO,
  EmployeeService,
  EmployeeUserInfoDTO,
  UserService,
} from "src/shared/api/generated";
import { isDeepEqual } from "src/shared/util/is-deep-equal";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";

@Component({
  selector: "[app-tr-user-management]",
  templateUrl: "./tr-user-management.component.html",
  styleUrls: ["./tr-user-management.component.scss"],
})
export class TrUserManagementComponent {
  @Input() employeeWithUserInfo: EmployeeUserInfoDTO =
    {} as EmployeeUserInfoDTO;
  @Input() companies: CompanyDTO[] = [];
  @Input() createMode: boolean = false;
  @Output() employeeSaved = new EventEmitter<void>();
  selectedRow: number = -1;
  isUser: boolean = false;
  visibleCompanyId?: number;

  editEmployeeWithUserInfo: EmployeeUserInfoDTO = {} as EmployeeUserInfoDTO;
  editedCell: string = "";
  saved: boolean = false;

  constructor(
    private employeeService: EmployeeService,
    private userService: UserService,
    private confirmationModalService: ConfirmationModalService
  ) {}

  ngOnInit(): void {
    this.isUser = this.employeeWithUserInfo.userId !== -1;
    this.resetEditedEmployeeWithUserInfo();

    if (this.employeeWithUserInfo.userId == -1) {
      this.visibleCompanyId = undefined;
    } else if (this.employeeWithUserInfo.global) {
      this.visibleCompanyId = -1;
    } else {
      this.visibleCompanyId = this.employeeWithUserInfo.visibleCompanies[0].id;
    }
  }

  resetEditedEmployeeWithUserInfo() {
    this.editEmployeeWithUserInfo = JSON.parse(JSON.stringify(this.employeeWithUserInfo));
  }

  saveEmployeeWithUserInfo(): void {
    if (this.saved) {
      return;
    }
    if (
      isDeepEqual(this.editEmployeeWithUserInfo, this.employeeWithUserInfo) ||
      this.editEmployeeWithUserInfo.name.trim() === "" ||
      this.editEmployeeWithUserInfo.email.trim() === ""
    ) {
      this.setEditStateFalse();
      return;
    }
    this.saved = true;

    this.employeeService
      .updateEmployeeFromSettings(this.editEmployeeWithUserInfo)
      .subscribe({
        next: (_) => {
          this.employeeSaved.emit();
        },
        error: (error) => {
          if (
            error.error.message ===
            "Employee already exists with the same email address"
          ) {
            this.confirmationModalService.openConfirmationModal(
              "employee.error.title",
              "employee.error.duplicate_email",
              true
            );
          }
        }
      });
      this.setEditStateFalse();
  }

  setEditStateFalse() {
    if (this.createMode) {
      return;
    }
    this.resetEditedEmployeeWithUserInfo();
    this.editedCell = "";
    this.saved = false;
  }

  setEditStateTrue(key: string) {
    if (this.createMode) {
      return;
    }
    this.editedCell = key;
    this.saved = false;
  }

  manageUser($event: any): void {
    if (this.createMode) {
      return;
    }
    if ($event.currentTarget.checked) {
      this.addUser();
    } else {
      this.deleteUser();
    }
  }

  addUser(): void {
    this.userService
      .createUser(this.editEmployeeWithUserInfo.employeeId!)
      .subscribe({
        next: (_) => {
          this.employeeSaved.emit();
        },
      });
  }

  deleteUser(): void {
    this.userService.deleteUser(this.editEmployeeWithUserInfo.userId).subscribe({
      next: (_) => {
        this.employeeSaved.emit();
      },
      error: (error) => {
        
      }
    });
    this.setEditStateFalse();
  }

  setAdmin(): void {
    this.userService
      .setAdmin(
        this.editEmployeeWithUserInfo.userId,
        this.editEmployeeWithUserInfo.admin
      )
      .subscribe({
        next: (_) => {
          this.employeeSaved.emit();
        },
      });
      this.setEditStateFalse();
  }

  setSales(): void {
    this.userService
      .setSales(
        this.editEmployeeWithUserInfo.userId,
        this.editEmployeeWithUserInfo.sales
      )
      .subscribe({
        next: (_) => {
          this.employeeSaved.emit();
        },
      });
      this.setEditStateFalse();
  }

  setVisibleCompanies(): void {
    if (this.createMode) {
      return;
    }
    if (this.visibleCompanyId) {
      this.userService
        .setVisibleCompanies(
          this.editEmployeeWithUserInfo.userId,
          this.visibleCompanyId
        )
        .subscribe({
          next: (_) => {
            this.employeeSaved.emit();
          },
        });
    }
    this.setEditStateFalse();
  }

  isEditingDisabled(): boolean {
    if (this.employeeWithUserInfo.employeeId && this.createMode) {
      return true;
    }
    return false;
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }
}
