<div
  class="pill d-flex justify-content-center align-items-center"
  [ngClass]="collectClasses()"
>
  {{ competency.name }}
  <ng-container *ngFor="let command of commands">
    <fa-icon
      class="command ms-2"
      [icon]="['fas', command.icon]"
      (click)="command.function(competency)"
      *ngIf="
        !command.alt || !command.alt.isAltActive(competency);
        else altCommand
      "
    ></fa-icon>
    <ng-template #altCommand>
      <fa-icon
        class="command ms-2"
        [icon]="['fas', command.alt?.icon!]"
        (click)="command.alt?.function(competency)"
      ></fa-icon>
    </ng-template>
  </ng-container>
</div>
