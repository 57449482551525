/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LookingForProjectDTOCompetencies } from './lookingForProjectDTOCompetencies';


export interface LookingForProjectDTO { 
    id: number;
    status: LookingForProjectDTO.StatusEnum;
    employeeId: number;
    employeeName: string;
    positionName?: string;
    seniority?: LookingForProjectDTO.SeniorityEnum;
    competencies: LookingForProjectDTOCompetencies;
    averageOfTop3Competencies?: number;
    assigneeId: number;
    comment?: string;
    freeFrom: string;
}
export namespace LookingForProjectDTO {
    export type StatusEnum = 'WAITING' | 'IN_PROGRESS' | 'DONE';
    export const StatusEnum = {
        Waiting: 'WAITING' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Done: 'DONE' as StatusEnum
    };
    export type SeniorityEnum = 'JUNIOR' | 'JUNIOR_MEDIOR' | 'MEDIOR' | 'MEDIOR_SENIOR' | 'SENIOR';
    export const SeniorityEnum = {
        Junior: 'JUNIOR' as SeniorityEnum,
        JuniorMedior: 'JUNIOR_MEDIOR' as SeniorityEnum,
        Medior: 'MEDIOR' as SeniorityEnum,
        MediorSenior: 'MEDIOR_SENIOR' as SeniorityEnum,
        Senior: 'SENIOR' as SeniorityEnum
    };
}


