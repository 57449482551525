import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  LookingForProjectDTO,
  LookingForProjectService,
  LookingForResourceDTO,
  LookingForResourceService,
} from "src/shared/api/generated";
import { isDeepEqual } from "src/shared/util/is-deep-equal";

@Component({
  selector: "app-looking-for-comment-modal",
  templateUrl: "./looking-for-comment-modal.component.html",
  styleUrls: ["./looking-for-comment-modal.component.scss"],
})
export class LookingForCommentModalComponent {
  @Input() lookingForEntity: LookingForProjectDTO & LookingForResourceDTO =
    {} as LookingForProjectDTO & LookingForResourceDTO;
  @Input() entityName: string = "";
  @Input() mode: string = "looking-for-project";
  editLookingForEntity: LookingForProjectDTO & LookingForResourceDTO =
  {} as LookingForProjectDTO & LookingForResourceDTO;
  editMode: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public lookingForProjectService: LookingForProjectService,
    public lookingForResourceService: LookingForResourceService
  ) {}

  ngOnInit(): void {
    this.resetLookingForEntity();
  }

  saveLookingForProject(){
    this.lookingForProjectService
    .updateLookingForProject({
      id: this.editLookingForEntity.id,
      assigneeId: this.editLookingForEntity.assigneeId,
      comment: this.editLookingForEntity.comment,
      status: this.editLookingForEntity.status,
      freeFrom: this.editLookingForEntity.freeFrom,
    })
    .subscribe(() => {
      this.activeModal.close(true);
    });
  }

  saveLookingForResource() {
    this.lookingForResourceService
    .updateLookingForResource({
      id: this.editLookingForEntity.id,
      assigneeId: this.editLookingForEntity.assigneeId,
      comment: this.editLookingForEntity.comment,
      status: this.editLookingForEntity.status,
      requestStart: this.editLookingForEntity.requestStart,
      requestEnd: this.editLookingForEntity.requestEnd,
      fte: this.editLookingForEntity.fte,
    })
    .subscribe(() => {
      this.activeModal.close(true);
    });
  }

  setEditMode() {
    this.editMode = !this.editMode;
  }

  isDisabled() {
    return isDeepEqual(this.lookingForEntity, this.editLookingForEntity);
  }

  resetLookingForEntity() {
    this.editLookingForEntity = JSON.parse(
      JSON.stringify(this.lookingForEntity)
    );
  }
}
