import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyService, CompanyDTO } from 'src/shared/api/generated';
import { CompetencyManagerService } from 'src/shared/services/competency-manager.service';
import { showLoadingSpinner } from 'src/shared/operators/loading-spinner.operator';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent {
  visibleCompanies: CompanyDTO[] = [];

  constructor(
    private companyService: CompanyService,
    private spinnerService: NgxSpinnerService,
    private competencymanagerService: CompetencyManagerService
  ) {}

  ngOnInit(): void {
    this.refreshCompanies();
    this.competencymanagerService.clearBothCompetencies();
  }

  refreshCompanies() {
    this.companyService.getVisibleCompanies()
    .pipe(showLoadingSpinner(this.spinnerService)).subscribe((companies) => {
      this.visibleCompanies = [...companies].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    });
  }
}
