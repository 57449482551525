import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  EmployeeDTO,
  EmployeeService,
  PositionDTO,
} from "src/shared/api/generated";
import { DisplayAndEditEmployeeCompetencyDto } from "src/shared/models/display-and-edit-employee-competency.model";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { isDeepEqual } from "src/shared/util/is-deep-equal";
import { Subscription } from "rxjs";
import { convertEmployeeCompetencies } from "src/shared/util/convert-employee-competencies";
import { ReadOnlyProfileModalComponent } from "../../read-only-profile-modal/read-only-profile-modal.component";
import { EducationModalComponent } from "../education-modal/education-modal.component";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";

@Component({
  selector: "[app-tr-employee]",
  templateUrl: "./tr-employee.component.html",
  styleUrls: ["./tr-employee.component.scss"],
})
export class TrEmployeeComponent implements OnInit, OnDestroy {
  @Input() employee: EmployeeDTO = {} as EmployeeDTO;
  @Input() positions: PositionDTO[] = [];
  @Output() employeeSaved = new EventEmitter<void>();
  @Input() searchTerm: string | undefined = "";
  @Input() search: boolean = false;
  @Input() allCompetencies: Map<number, string> = new Map();
  @Input() createMode: boolean = false;

  seniorities = EmployeeDTO.SeniorityEnum;

  editEmployee: EmployeeDTO = {} as EmployeeDTO;
  selectedCellEmployeeId: number | null = null;
  averageOfTop3Competencies: number = 0;

  editedCell: string = "";
  saved: boolean = false;
  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyManagerService: CompetencyManagerService,
    private modalService: NgbModal,
    private employeeService: EmployeeService,
    private confirmationModalService: ConfirmationModalService
  ) {}

  ngOnInit(): void {
    this.resetEditedEmployee();
    if (this.createMode) {
      this.competencyManagerService.clearEmployeeCompetency();
    }
    this.averageOfTop3Competencies =
      Math.round(this.employee.averageOfTop3Competencies! * 100) / 100;
    this.subscriptions.add(
      this.competencyManagerService.employeeCompetency.subscribe(
        (data: DisplayAndEditEmployeeCompetencyDto) => {
          this.selectedCellEmployeeId = data.employeeId;
          if (data.employeeId && data.employeeId === this.employee.id) {
            this.employee.competencies = Object.fromEntries(
              data.competencyList.map((c) => [c.id, c.level])
            );
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  saveEmployee() {
    if (this.createMode) {
      return;
    }
    if (this.saved) {
      return;
    }
    if (
      isDeepEqual(this.editEmployee, this.employee) ||
      this.editEmployee.name.trim() === "" ||
      this.editEmployee.email.trim() === ""
    ) {
      this.setEditStateFalse();
      return;
    }
    this.saved = true;

    this.employeeService.updateEmployee(this.editEmployee).subscribe({
      next: (_) => {
        this.employeeSaved.emit();
      },
      error: (error) => {
        if (
          error.error.message ===
          "Employee already exists with the same email address"
        ) {
          this.confirmationModalService.openConfirmationModal(
            "employee.error.title",
            "employee.error.duplicate_email",
            true
          );
        }
      },
    });
    this.setEditStateFalse();
  }

  setEditStateFalse() {
    if (this.createMode) {
      return;
    }
    this.resetEditedEmployee();
    this.editedCell = "";
    this.saved = false;
  }

  setEditStateTrue(key: string) {
    if (this.createMode) {
      return;
    }

    if (key === "externalPartner" && this.employee.internal) {
      return;
    }

    this.editedCell = key;
    this.saved = false;
  }

  resetEditedEmployee() {
    this.editEmployee = JSON.parse(JSON.stringify(this.employee));
  }

  selectCompetencyCell($event: MouseEvent) {
    this.inputClicked($event);
    if (!this.employee.id || this.createMode) {
      return;
    }
    if (this.employee.id === this.selectedCellEmployeeId) {
      this.competencyManagerService.clearEmployeeCompetency();
      return;
    }
    this.competencyManagerService.setEmployeeCompetency(
      false,
      this.employee.id,
      this.employee.name,
      this.employee.competencies
    );
  }

  isSearchResult(): boolean {
    if (!this.search || !this.searchTerm) {
      return false;
    }
    return (
      convertEmployeeCompetencies(this.employee.competencies).filter(
        (competency) => {
          return !!this.allCompetencies
            .get(competency.id)
            ?.toLowerCase()
            .includes(this.searchTerm?.toLowerCase() ?? "");
        }
      ).length !== 0
    );
  }

  isEditingDisabled(): boolean {
    if (this.employee.id && this.createMode) {
      return true;
    }
    return false;
  }

  openReadOnlyProfile(id: number | undefined) {
    if (id) {
      const modalRef = this.modalService.open(ReadOnlyProfileModalComponent);
      modalRef.componentInstance.employeeId = id;
    }
  }

  openEducationModal() {
    if (!this.employee.id || this.createMode) {
      return;
    }
    const modalRef = this.modalService.open(EducationModalComponent, {
      size: "xl",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.employeeName = this.employee.name;
    modalRef.componentInstance.employeeId = this.employee.id;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.employeeSaved.emit();
        }
      },
      () => {}
    );
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }
}
