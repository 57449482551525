<div *ngIf="!profiles.length" class="image-container">
  <img src="..\..\..\assets\img\squirrel_sleep.png" />
</div>
<div *ngIf="profiles.length" class="table-container">
  <table
    class="table table-light table-hover table-striped table-bordered align-middle scrollable-table w-100"
  >
    <thead>
      <tr>
        <th
          scope="col"
          sortable="name"
          (sort)="onSort($event)"
          class="table-header text-center name"
        >
          {{ "basket.employee_table.name" | translate }}
        </th>
        <th
          scope="col"
          sortable="email"
          (sort)="onSort($event)"
          class="table-header text-center email"
        >
          {{ "basket.employee_table.email" | translate }}
        </th>
        <th scope="col" class="table-header text-center">
          {{ "basket.employee_table.status.header" | translate }}
        </th>
        <th scope="col" class="table-header text-center"></th>
        <th scope="col" class="table-header text-center"></th>
      </tr>
    </thead>
    <tbody
      cdkDropList
      [cdkDropListData]="profiles"
      cdkDropListConnectedTo="queue"
      cdkDropListSortingDisabled
    >
      <tr
        app-tr-basket-employee
        *ngFor="let profile of profiles"
        [profile]="profile"
        [basketId]="basketId"
        [profileExportQueue]="profileExportQueue"
        (profileRemoved)="onProfileRemoved($event)"
        (profileUpdated)="onProfileUpdatedInBasket($event)"
        cdkDrag
        [cdkDragData]="profile"
      >
        <div *cdkDragPreview class="custom-preview">
          {{ profile.employeeName }}
        </div>
      </tr>
    </tbody>
  </table>
</div>
