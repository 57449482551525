import { Component, OnInit } from '@angular/core';
import { CompetencyManagerService } from 'src/shared/services/competency-manager.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private competencyManagerService: CompetencyManagerService) {}

  ngOnInit(): void {
    this.competencyManagerService.clearBothCompetencies();
  }
}
