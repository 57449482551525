import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { debounceTime, distinctUntilChanged, forkJoin, switchMap } from "rxjs";
import {
  CompetencyDTO,
  CompetencyGroupDTO,
  CompetencyGroupService,
  CompetencyService,
} from "src/shared/api/generated";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/shared/directives/ngbd-sortable-header.directive";
import { AddOrEditCompetencyModalComponent } from "../../competency/add-or-edit-competency-modal/add-or-edit-competency-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-table-competency-management",
  templateUrl: "./table-competency-management.component.html",
  styleUrls: ["./table-competency-management.component.scss"],
})
export class TableCompetencyManagementComponent implements OnInit {
  competencies: CompetencyDTO[] = [];
  competencyGroups: CompetencyGroupDTO[] = [];
  page = 1;
  pageSize = 15;
  collectionSize = 0;
  sorting: SortEvent = { column: "name", direction: "asc" };
  searchText = new FormControl("");

  showErrorToast: boolean = false;

  constructor(
    private competencyService: CompetencyService,
    private competencyGroupService: CompetencyGroupService,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initialLoad();
    this.searchCompetencies();
  }

  refreshCompetencies() {
    this.getCompetencies(this.searchText.value ?? "")
    .pipe(showLoadingSpinner(this.spinnerService))
    .subscribe(
      (competencyPage) => {
        this.competencies = competencyPage.content ?? [];
        this.collectionSize = competencyPage.totalElements ?? 0;
      }
    );
  }

  initialLoad() {
    forkJoin([this.getSelectableGroups(), this.getCompetencies()])
    .pipe(showLoadingSpinner(this.spinnerService))
    .subscribe(([groups, competencies]) => {
      this.competencyGroups = groups;
      this.competencies = competencies.content ?? [];
      this.collectionSize = competencies.totalElements ?? 0;
    });
  }

  getSelectableGroups() {
    return this.competencyGroupService.getAllSelectableCompetencyGroups();
  }

  searchCompetencies() {
    this.searchText.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((text) => this.getCompetencies(text ?? "").pipe(showLoadingSpinner(this.spinnerService)))
      )
      .subscribe((competencyPage) => {
        this.competencies = competencyPage.content ?? [];
        this.collectionSize = competencyPage.totalElements ?? 0;
      });
  }

  getCompetencies(searchTerm: string = "") {
    return this.competencyService.getAllPagedCompetencies(
      searchTerm.trim(),
      this.pageSize,
      this.page - 1,
      this.sorting.column,
      this.sorting.direction
    );
  }

  createCompetency() {
    const modalRef = this.modalService.open(AddOrEditCompetencyModalComponent);
    modalRef.componentInstance.competency = {
      id: undefined,
      name: "",
      competencyGroupId: this.competencyGroups[0].id,
    };
    modalRef.componentInstance.competencyGroups = this.competencyGroups;
    modalRef.componentInstance.createMode = true;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.refreshCompetencies();
        }
      },
      () => {}
    );
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> =
    new QueryList();

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.sorting = { column: column, direction: direction };
    this.refreshCompetencies();
  }
}
