<div class="main-container d-flex flex-column">
  <label class="title-label">{{ "read_only_profile.title" | translate }}</label>
  <label class="name-label text-overflow-dotted">{{
    profile.employeeName
  }}</label>
  <label
    class="position-label text-overflow-dotted"
    *ngIf="profile?.position"
    >{{ profile.position }}</label
  >

  <div class="content d-flex flex-column">
    <div class="seniority" *ngIf="profile.seniority">
      <div
        class="seniority-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">{{
          "read_only_profile.seniority.title" | translate
        }}</label>
      </div>
      <div class="seniority-item d-flex flex-row justify-content-between">
        {{ "read_only_profile.seniority." + profile.seniority | translate }}
      </div>
    </div>

    <div class="experience">
      <div
        class="experience-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">{{
          "read_only_profile.experience_title" | translate
        }}</label>
      </div>
      <div class="experience-item" *ngFor="let project of getProjects()">
        <div class="project-title-row d-flex flex-row">
          <label class="project-title">{{ project.year }}</label>
          <label class="project-title">{{ project.projectName }}</label>
        </div>
        <label
          class="project-alternative-name"
          *ngIf="project.alternativeName"
          >{{ project.alternativeName }}</label
        >
        <div class="project-info" *ngIf="project.description">
          <label class="experience-item-section-title">{{
            "read_only_profile.project_scope" | translate
          }}</label>
          <ul>
            <li *ngIf="project.description">{{ project.description }}</li>
          </ul>
        </div>
        <div class="project-tasks" *ngIf="project.tasks?.length">
          <label class="experience-item-section-title">{{
            "read_only_profile.project_tasks" | translate
          }}</label>
          <ul>
            <li *ngFor="let task of project.tasks">
              {{ task }}
            </li>
          </ul>
        </div>
      </div>
      <div class="competencies">
        <label class="section-title">{{
          "read_only_profile.competencies_title" | translate
        }}</label>
        <div class="competency-container">
          <div
            class="competency-category-container d-flex flex-row justify-content-between"
          >
            <label class="competency-category-title">{{
              "read_only_profile.competency_technological" | translate
            }}</label>
          </div>
          <div
            class="competency-item d-flex flex-row"
            *ngFor="let competency of getTechnologicalCompetencies()"
          >
            <label>{{ competency.level }}</label>
            <label>{{ competency.competencyDTO?.name }}</label>
          </div>
        </div>
        <div class="competency-container">
          <div
            class="competency-category-container d-flex flex-row justify-content-between"
          >
            <div>
              <label class="competency-category-title">{{
                "read_only_profile.competency_language" | translate
              }}</label>
              <span
                ngbTooltip="{{
                  'read_only_profile.language_level_tooltip' | translate
                }}"
                placement="bottom"
                container="body"
                class="ms-1"
                ><fa-icon [icon]="['fas', 'circle-info']"></fa-icon
              ></span>
            </div>
          </div>
          <div
            class="competency-item d-flex flex-row"
            *ngFor="let competency of getLanguageCompetencies()"
          >
            <label>{{ competency.level }}</label>
            <label>{{ competency.competencyDTO?.name }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions d-flex flex-row justify-content-center">
    <button
      type="button"
      class="btn btn-primary ok-button"
      (click)="activeModal.close()"
    >
      {{ "read_only_profile.close" | translate }}
    </button>
  </div>
</div>
