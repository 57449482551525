<div
  class="floating-button"
  [class.active]="showDropdown"
  (click)="toggleDropdown()"
>
  <fa-icon
    class="basket-icon"
    [icon]="['fas', 'basket-shopping']"
    size="2xl"
  ></fa-icon>
  <span
    *ngIf="areBasketsNotEmpty()"
    class="not-empty-badge position-absolute translate-middle p-2 border border-light rounded-circle"
  >
  </span>
  <div *ngIf="showDropdown" class="dropdown">
    <ul>
      <li (click)="openNewBasketModal()">
        {{ "basket.new_basket" | translate }}
      </li>
      <hr class="divider" />
      <li
        *ngFor="let basket of allBaskets"
        (click)="addSelectedEmployeeToBasket(basket.id!)"
      >
        {{ basket.name }}
      </li>
    </ul>
  </div>
</div>

<ngb-toast
  *ngIf="showSuccessToast"
  class="success-toast bg-success text-light"
  [autohide]="true"
  (hidden)="showSuccessToast = false"
  [delay]="3000"
  #successToast
  >{{ "basket.employee_added" | translate }}</ngb-toast
>
