import { Component, Input, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import {
  Observable,
  OperatorFunction,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
} from "rxjs";
import {
  EmployeeSelectDTO,
  LookingForProjectCreateDTO,
  LookingForProjectService,
  UserSelectDTO,
} from "src/shared/api/generated";

@Component({
  selector: "app-create-looking-for-project",
  templateUrl: "./create-looking-for-project.component.html",
  styleUrls: ["./create-looking-for-project.component.scss"],
})
export class CreateLookingForProjectComponent {
  @Input() employees: EmployeeSelectDTO[] = [];
  @Input() users: UserSelectDTO[] = [];
  newLookingForProject: LookingForProjectCreateDTO = {
    status: "WAITING",
    employeeId: -1,
    assigneeId: -1,
    freeFrom: "",
  };
  selectedEmployee: EmployeeSelectDTO = { id: -1, name: "" };
  selectedAssignee: UserSelectDTO = { id: -1, name: "" };
  error: boolean = false;
  errorMessage: string = "";
  @ViewChild("employeeInstance", { static: true })
  employeeInstance: NgbTypeahead = {} as NgbTypeahead;
  @ViewChild("assigneeInstance", { static: true })
  assigneeInstance: NgbTypeahead = {} as NgbTypeahead;
  employeeFocus$ = new Subject<string>();
  assigneeFocus$ = new Subject<string>();
  employeeClick$ = new Subject<string>();
  assigneeClick$ = new Subject<string>();

  constructor(
    public activeModal: NgbActiveModal,
    private lookingForProjectService: LookingForProjectService,
    private translate: TranslateService
  ) {}

  saveNewEmployee() {
    if (this.isDisabled()) {
      return;
    }
    this.newLookingForProject.employeeId = this.selectedEmployee.id!;
    this.newLookingForProject.assigneeId = this.selectedAssignee.id!;
    this.lookingForProjectService
      .createLookingForProject(this.newLookingForProject)
      .subscribe({
        next: (_) => {
          this.activeModal.close(true);
        },
        error: (error) => {
          if (
            error.error.message ===
            "There is already an active Looking For Project for the given employee"
          )
            this.errorMessage = this.translate.instant(
              "home.looking_for_project.create_modal.error.already_has_active_entry"
            );
          this.error = true;
        },
      });
  }

  isDisabled(): boolean {
    return (
      this.selectedEmployee?.id! < 0 ||
      this.selectedAssignee?.id! < 0 ||
      this.newLookingForProject.freeFrom.trim() === ""
    );
  }

  closeAlert() {
    this.error = false;
  }

  searchEmployee: OperatorFunction<string, EmployeeSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.employeeClick$.pipe(
      filter(() => !this.employeeInstance.isPopupOpen())
    );
    const inputFocus$ = this.employeeFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : this.employees.filter((v) => new RegExp(term, "miu").test(v.name!))
        ).slice(0, 10)
      )
    );
  };

  searchAssignee: OperatorFunction<string, UserSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.assigneeClick$.pipe(
      filter(() => !this.assigneeInstance.isPopupOpen())
    );
    const inputFocus$ = this.assigneeFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? this.users
          : this.users.filter((v) => new RegExp(term, "miu").test(v.name!))
        )
          .sort((a, b) => a.name!.localeCompare(b.name!))
          .slice(0, 10)
      )
    );
  };

  formatter = (x: EmployeeSelectDTO | UserSelectDTO) => x.name!;
}
