<td (dblclick)="setEditStateTrue('name')">
  <input
    *ngIf="this.editedCell === 'name'"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="saveCompetency()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="setEditStateFalse()"
    (change)="saveCompetency()"
    [(ngModel)]="editedCompetency.name"
    (click)="inputClicked($event)"
  />
  <label *ngIf="this.editedCell !== 'name'" class="text-overflow-dotted">{{
    competency.name
  }}</label>
</td>
<td>
  <select
    (change)="saveCompetency()"
    [(ngModel)]="editedCompetency.competencyGroup.id"
  >
    <option *ngFor="let type of competencyGroups" [value]="type.id">
      {{ type.name }}
    </option>
  </select>
</td>
<td class="text-center">
  <fa-icon
    class="delete-icon"
    (click)="onDeleteCompetency(competency.id!)"
    [icon]="['fas', 'trash']"
  ></fa-icon>
</td>
