<div class="main-container">
  <label class="title-label">{{
    "project.members_modal.title" | translate
  }}</label>
  <label class="name-label text-overflow-dotted">{{ projectName }}</label>
  <div class="member-container" *ngIf="projectMembers.length != 0">
    <div class="member-list">
      <div ngbAccordion class="accordion-flush pb-3" [closeOthers]="true">
        <div
          ngbAccordionItem
          *ngFor="let memberPlan of projectMembers"
          (hide)="cancelEditing()"
        >
          <h2 ngbAccordionHeader class="w-100">
            <button ngbAccordionButton class="py-1 w-100">
              <div
                class="accordion-header d-flex flex-row justify-content-between align-items-center pe-2"
              >
                <span class="member-name pe-1 py-1"
                  ><b>{{ memberPlan.employeeName }}</b> </span
                ><span class="member-position pe-1 py-1"> {{ memberPlan.employeePosition }}</span>
                <ngb-progressbar
                  class="progressbar"
                  [showValue]="true"
                  type="primary"
                  [value]="memberPlan.percentage!"
                />
              </div>
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="d-flex flex-column">
                  <div class="pb-2">
                    <div>
                      <label class="task-title-label pe-2">{{ 'project.members_modal.tasks' | translate }}</label>
                      <span *ngIf="editedMemberPlan.planId !== memberPlan.planId">
                        <fa-icon
                          class="edit-icon"
                          [icon]="['fas', 'pen']"
                          (click)="setEditedMemberPlan(memberPlan)"
                        ></fa-icon>
                      </span>
                      <span *ngIf="editedMemberPlan.planId === memberPlan.planId">
                        <fa-icon
                          class="save-icon"
                          [icon]="['fas', 'check']"
                          (click)="saveMemberPlan()"
                        ></fa-icon>
                        <fa-icon
                          class="cancel-icon ps-2"
                          [icon]="['fas', 'xmark']"
                          (click)="cancelEditing()"
                        ></fa-icon>
                      </span>
                    </div>

                    <div
                      class="ms-2 description-label"
                      *ngIf="editedMemberPlan.planId !== memberPlan.planId"
                      >{{ memberPlan.description }}</div
                    >
                    <textarea
                      class="px-2"
                      rows="4"
                      placeholder="{{ 'project.members_modal.placeholder' | translate }}"
                      *ngIf="editedMemberPlan.planId === memberPlan.planId"
                      [(ngModel)]="editedMemberPlan.description"
                      (keydown.enter)="saveMemberPlan()"
                      (keydown.escape)="cancelEditing()"
                    ></textarea>
                  </div>
                  <label class="tech-title-label">{{ 'project.members_modal.technologies' | translate }}</label>
                  <div
                    *ngFor="
                      let competency of memberPlan.linkedCompetencies! | keyvalue
                    "
                  >
                    <span class="ms-2 pe-2">{{ competency.value }}</span>
                    <span>{{
                      allCompetencies.get(convertToNumber(competency.key))
                    }}</span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="empty-member-container" *ngIf="projectMembers.length == 0">
      <label class="text-center">
        {{ "project.members_modal.empty_members" | translate }}
      </label>
    </div>

    <div class="actions">
      <button
        type="button"
        class="btn btn-primary close-button"
        (click)="activeModal.close()"
      >
        {{ "project.members_modal.close" | translate }}
      </button>
    </div>
</div>
