<div class="d-flex flex-column justify-content-between h-100 position-relative">
  <div class="d-flex flex-column main-container" style="min-height: 0">
    <div class="d-flex flex-row w-100 justify-content-start actions">
      <div>
        <button
          *ngIf="!createEducationMode"
          class="btn btn-primary add-button ms-2"
          (click)="addEmptyEducation()"
        >
          {{ "employee.education_modal.education_table.add" | translate }}
        </button>
        <button
          *ngIf="createEducationMode"
          class="btn btn-primary add-button ms-2"
          [disabled]="isCreateDisabled()"
          (click)="createEducation()"
        >
          {{ "employee.education_modal.education_table.save" | translate }}
        </button>
        <button
          *ngIf="createEducationMode"
          class="btn btn-primary add-button ms-2"
          (click)="cancelCreateEducation()"
        >
          {{ "employee.education_modal.education_table.cancel" | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="educations.length" class="table-container">
      <table
        class="table table-light table-hover table-striped table-bordered align-middle scrollable-table"
      >
        <thead>
          <tr>
            <th scope="col" class="table-header text-center">
              {{ "employee.education_modal.education_table.code" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "employee.education_modal.education_table.name" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "employee.education_modal.education_table.start" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "employee.education_modal.education_table.end" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{
                "employee.education_modal.education_table.valid_until"
                  | translate
              }}
            </th>
            <th scope="col" class="table-header">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr app-tr-education
          *ngFor="let education of educations"
          [education]="education"
          [createMode]="createEducationMode"
          (educationSaved)="onEducationSaved()"
          (educationSaveFailed)="onEducationSaveFailed($event)"
          >
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
