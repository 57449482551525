<div class="modal-header">
  <div>
    <h4 class="modal-title">
      {{ "plan.create_modal.title" | translate }}
    </h4>
    <div class="label-container">
      <label class="name-label">{{ employeeName }}</label>
    </div>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body main-container">
  <div class="form-row">
    <label>{{ "plan.create_modal.project" | translate }}</label>
    <label
      *ngIf="selectedProject && selectedProject.id! >= 0"
      class="date-label"
      >{{ selectedProject.start | date }} -
      {{ selectedProject.end | date }}</label
    >
    <ng-template #resultTemplate let-r="result" let-t="term">
      <ngb-highlight class="typeahead-highlight me-1" [result]="r.name" [term]="t"></ngb-highlight>
      <label>({{ "project.state." + r.state | translate }})</label>
    </ng-template>
    <input
      type="text"
      [(ngModel)]="selectedProject"
      [ngbTypeahead]="searchProject"
      [resultTemplate]="resultTemplate"
      [inputFormatter]="inputFormatter"
      [editable]="false"
      (focus)="projectFocus$.next($any($event).target.value)"
      (click)="projectFocus$.next($any($event).target.value)"
      (selectItem)="fetchCompetencies($event)"
      #employeeInstance="ngbTypeahead"
    />
  </div>
  <div class="form-row">
    <label>{{ "plan.create_modal.start" | translate }}</label>
    <input [(ngModel)]="newPlan.from" type="date" />
  </div>
  <div class="form-row">
    <label>{{ "plan.create_modal.end" | translate }}</label>
    <input [(ngModel)]="newPlan.to" type="date" />
  </div>
  <div class="form-row">
    <label>{{ "plan.create_modal.percentage" | translate }}</label>
    <div class="percentage-input">
      <input
        [(ngModel)]="newPlan.percentage"
        type="number"
        min="0"
        max="100"
        class="percentage-number-input"
      />
      <input
        [(ngModel)]="newPlan.percentage"
        type="range"
        min="0"
        max="100"
        step="5"
      />
    </div>
  </div>
  <div class="form-row">
    <label>{{ "plan.create_modal.description" | translate }}</label>
    <div class="w-50">
      <textarea
        class="px-2"
        rows="4"
        placeholder="{{ 'plan.create_modal.placeholder' | translate }}"
        [(ngModel)]="newPlan.description"
      ></textarea>
    </div>
  </div>
  <div class="form-row">
    <label>{{ "plan.create_modal.competency" | translate }}</label>
    <div class="w-50">
      <app-competency-pill-container
        [competencies]="competencies"
        [allCompetencies]="allCompetencies"
        [employeeCompetencies]="employeeCompetencies"
        [commands]="commands"
        [isAddPillButtonActive]="true"
        (newCompetencyEvent)="checkAndAddNewCompetency($event)"
      ></app-competency-pill-container>
    </div>
  </div>
</div>
<div *ngIf="error" class="error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">{{
    errorMessage
  }}</ngb-alert>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary cancel-button"
    (click)="activeModal.close(false)"
  >
    {{ "plan.create_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary ok-button"
    [disabled]="isDisabled()"
    (click)="saveNewPlan()"
  >
    {{ "plan.create_modal.save" | translate }}
  </button>
</div>
