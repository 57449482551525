import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { EducationDTO, EducationService } from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-table-education",
  templateUrl: "./table-education.component.html",
  styleUrls: ["./table-education.component.scss"],
})
export class TableEducationComponent implements OnInit {
  @Input() employeeId!: number;
  @Output() educationSaved = new EventEmitter<void>();
  @Output() educationSaveFailed = new EventEmitter<string>();
  educations: EducationDTO[] = [];
  createEducationMode: boolean = false;

  constructor(
    private educationService: EducationService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.refreshEmployeeEducation();
  }

  refreshEmployeeEducation() {
    this.educationService.findAllByEmployeeId(this.employeeId)
    .pipe(showLoadingSpinner(this.spinnerService))
    .subscribe({
      next: (employeeEducation) => {
        this.educations = [...employeeEducation];
      },
    });
  }

  addEmptyEducation() {
    this.educations.unshift({
      id: undefined,
      name: "",
      code: undefined,
      start: "",
      end: "",
      validUntil: "",
      employeeId: this.employeeId,
    });
    this.createEducationMode = true;
  }

  isCreateDisabled() {
    let createEducation = this.educations.find((education) => !education.id);
    return createEducation?.name.trim() === "";
  }

  cancelCreateEducation() {
    this.createEducationMode = false;
    this.educations = this.educations.filter((education) => education.id);
  }

  createEducation() {
    this.educationService
      .createEducation(this.educations.find((education) => !education.id)!)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (_) => {
          this.educationSaved.emit();
          this.refreshEmployeeEducation();
          this.createEducationMode = false;
        },
        error: (error) => {
          this.educationSaveFailed.emit(error.error.message);
        },
      });
  }

  onEducationSaved() {
    this.educationSaved.emit();
    this.refreshEmployeeEducation();
  }

  onEducationSaveFailed(error: string) {
    this.educationSaveFailed.emit(error);
  }
}
