/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlanMemberDTO } from './planMemberDTO';
import { ProjectCompetencyDTO } from './projectCompetencyDTO';


export interface ProjectTableDTO { 
    id?: number;
    name: string;
    start?: string;
    end?: string;
    projectState: ProjectTableDTO.ProjectStateEnum;
    companyId: number;
    fte?: number;
    internal: boolean;
    members?: Array<PlanMemberDTO>;
    competencies?: Array<ProjectCompetencyDTO>;
    alternativeName?: string;
    description?: string;
}
export namespace ProjectTableDTO {
    export type ProjectStateEnum = 'PROSPECT' | 'ACTIVE' | 'PLANNED' | 'CLOSED';
    export const ProjectStateEnum = {
        Prospect: 'PROSPECT' as ProjectStateEnum,
        Active: 'ACTIVE' as ProjectStateEnum,
        Planned: 'PLANNED' as ProjectStateEnum,
        Closed: 'CLOSED' as ProjectStateEnum
    };
}


