import { inject } from "@angular/core";
import { CanActivateChildFn, CanActivateFn, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { ConfigurationService } from "../services/configuration.service";
import { JwtHelperService } from "@auth0/angular-jwt";

export const loginGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const config = inject(ConfigurationService);
  const token = config.fetchToken("accessToken");
  if (!token) {
    return router.createUrlTree(["login"]);
  } else if (!isUserRepresent(authService)) {
    authService.decodeAndSaveUser(token);
    return isUserRepresent(authService)
      ? true
      : router.createUrlTree(["login"]);
  }
  return true;
};

export const loginGuardChild: CanActivateChildFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const config = inject(ConfigurationService);
  const token = config.fetchToken("accessToken");
  if (!token) {
    return router.createUrlTree(["login"]);
  } else if (!isUserRepresent(authService)) {
    authService.decodeAndSaveUser(token);
    return isUserRepresent(authService)
      ? true
      : router.createUrlTree(["login"]);
  }
  return true;
};

export const checkAdmin: CanActivateChildFn = () => {
  let jwtHelper: JwtHelperService = new JwtHelperService();
  const router = inject(Router);
  const config = inject(ConfigurationService);
  const token = config.fetchToken("accessToken");

  if (jwtHelper.decodeToken(token).admin) {
    return true;
  }
  return router.createUrlTree(["home"]);
};

export const checkNotSalesUser: CanActivateChildFn = () => {
  let jwtHelper: JwtHelperService = new JwtHelperService();
  const router = inject(Router);
  const config = inject(ConfigurationService);
  const token = config.fetchToken("accessToken");

  if (!jwtHelper.decodeToken(token).sales) {
    return true;
  }
  return router.createUrlTree(["smart-search"]);
};

const isUserRepresent = (authService: AuthService): boolean => {
  return !!authService.user.value;
};
