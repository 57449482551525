/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProjectSelectDTO { 
    id?: number;
    name?: string;
    start?: string;
    end?: string;
    state?: ProjectSelectDTO.StateEnum;
}
export namespace ProjectSelectDTO {
    export type StateEnum = 'PROSPECT' | 'ACTIVE' | 'PLANNED' | 'CLOSED';
    export const StateEnum = {
        Prospect: 'PROSPECT' as StateEnum,
        Active: 'ACTIVE' as StateEnum,
        Planned: 'PLANNED' as StateEnum,
        Closed: 'CLOSED' as StateEnum
    };
}


