<div class="main-container d-flex flex-column">
  <label class="title-label">{{
    "profile_export.profile_modify_modal.title" | translate
  }}</label>
  <label class="name-label text-overflow-dotted">{{
    profile.employeeName
  }}</label>
  <label
    class="position-label text-overflow-dotted"
    *ngIf="profile?.employeePosition"
    >{{ profile.employeePosition }}</label
  >

  <div class="content d-flex flex-column">
    <div class="seniority">
      <div
        class="seniority-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">{{
          "profile_export.profile_modify_modal.seniority.title" | translate
        }}</label>
      </div>
      <div
        class="seniority-item d-flex flex-row justify-content-between"
        [ngClass]="!profile.showSeniority ? 'not-visible' : ''"
      >
        <select [(ngModel)]="profile.seniority" class="seniority-select">
          <option [ngValue]="null">---</option>
          <option
            *ngFor="let seniority of seniorities | keyvalue"
            [value]="seniority.value"
          >
            {{
              "profile_export.profile_modify_modal.seniority." + seniority.value
                | translate
            }}
          </option>
        </select>
        <fa-icon
          *ngIf="!profile.showSeniority"
          class="change-visibility-icon"
          [icon]="['fas', 'eye']"
          (click)="changeShowSeniority()"
        ></fa-icon>
        <fa-icon
          *ngIf="profile.showSeniority"
          class="change-visibility-icon"
          [icon]="['fas', 'eye-slash']"
          (click)="changeShowSeniority()"
        ></fa-icon>
      </div>
    </div>

    <div class="experience">
      <div
        class="experience-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">{{
          "profile_export.profile_modify_modal.experience_title" | translate
        }}</label>
        <button
          class="btn btn-primary btn-sm add-button"
          [disabled]="editExternalProjectMode"
          (click)="addExternalProject()"
        >
          {{ "profile_export.profile_modify_modal.add" | translate }}
        </button>
      </div>
      <div
        class="experience-item"
        *ngFor="let internalProject of profile.internalProjects"
        [ngClass]="!internalProject.visible ? 'not-visible' : ''"
      >
        <div class="project-title-row d-flex flex-row">
          <label class="project-title">{{
            internalProject.project?.year
          }}</label>
          <label class="project-title">{{
            internalProject.project?.projectName
          }}</label>
          <fa-icon
            *ngIf="!internalProject.visible"
            class="change-visibility-icon"
            [icon]="['fas', 'eye']"
            (click)="changeProjectVisibility(internalProject)"
          ></fa-icon>
          <fa-icon
            *ngIf="internalProject.visible"
            class="change-visibility-icon"
            [icon]="['fas', 'eye-slash']"
            (click)="changeProjectVisibility(internalProject)"
          ></fa-icon>
        </div>
        <ul *ngIf="internalProject.project?.tasks?.length">
          <li *ngFor="let task of getTasksByInternalProject(internalProject)">
            {{ task }}
          </li>
        </ul>
        <div>
          <app-competency-pill-container
            [competencies]="
              internalProject.project?.profileProjectCompetencies!
            "
            [commands]="commands"
            [isDragActive]="true"
          ></app-competency-pill-container>
        </div>
      </div>
      <div
        class="experience-item"
        *ngFor="let externalProject of profile.externalProjects; let i = index"
      >
        <ng-container
          *ngIf="editedExternalProjectIndex != i; else editExternalProjectMode"
        >
          <div class="project-title-row d-flex flex-row">
            <label class="project-title">{{ externalProject.year }}</label>
            <label class="project-title">{{
              externalProject.projectName
            }}</label>
            <div class="modification-icons">
              <fa-icon
                class="edit-icon"
                [icon]="['fas', 'pen']"
                (click)="startEditingExternalProject(i)"
              ></fa-icon>
              <fa-icon
                class="delete-icon"
                [icon]="['fas', 'trash']"
                (click)="removeExternalProject(externalProject)"
              ></fa-icon>
            </div>
          </div>
          <ul *ngIf="externalProject.task">
            <li>{{ externalProject.task }}</li>
          </ul>
        </ng-container>
        <ng-template #editExternalProjectMode>
          <div class="project-title-row d-flex flex-row">
            <input
              type="text"
              maxlength="255"
              class="edit-year-input"
              [(ngModel)]="editedExternalProject.year"
              placeholder="{{
                'profile_export.profile_modify_modal.project_year' | translate
              }}"
            />
            <input
              type="text"
              maxlength="255"
              [(ngModel)]="editedExternalProject.projectName"
              placeholder="{{
                'profile_export.profile_modify_modal.project_name' | translate
              }}"
            />
            <div class="modification-icons">
              <fa-icon
                class="save-edit-icon"
                [icon]="['fas', 'check']"
                (click)="saveProjectEdit()"
              ></fa-icon>
              <fa-icon
                class="cancel-edit-icon"
                [icon]="['fas', 'xmark']"
                (click)="cancelProjectEdit()"
              ></fa-icon>
            </div>
          </div>
          <div class="edit-task-row">
            <input
              type="text"
              maxlength="255"
              class="edit-task-input"
              [(ngModel)]="editedExternalProject.task"
              placeholder="{{
                'profile_export.profile_modify_modal.project_task' | translate
              }}"
            />
          </div>
        </ng-template>
      </div>
    </div>
    <div class="competencies">
      <label class="section-title">{{
        "profile_export.profile_modify_modal.competencies_title" | translate
      }}</label>
      <div class="competency-container">
        <div
          class="competency-category-container d-flex flex-row justify-content-between"
        >
          <label class="competency-category-title">{{
            "profile_export.profile_modify_modal.competency_technological"
              | translate
          }}</label>
          <select
            *ngIf="getTechnologicalCompetencies().length"
            [(ngModel)]="getTechnologicalCompetencies()[0].showCompetencyAs"
            (change)="changeTechnologicalCompetencyDisplayMode($event)"
          >
            <option
              *ngFor="let displayMode of competencyDisplayModes"
              [value]="displayMode"
            >
              {{
                "profile_export.profile_modify_modal.technological_competency_display_mode" +
                  "." +
                  displayMode | translate
              }}
            </option>
          </select>
        </div>
        <div
          class="competency-item d-flex flex-row"
          *ngFor="let competency of getTechnologicalCompetencies()"
          [ngClass]="!competency.visible ? 'not-visible' : ''"
        >
          <label>{{ competency.level }}</label>
          <label>{{ competency.competency?.name }}</label>
          <fa-icon
            *ngIf="!competency.visible"
            class="change-visibility-icon"
            [icon]="['fas', 'eye']"
            (click)="changeCompetencyVisibility(competency)"
          ></fa-icon>
          <fa-icon
            *ngIf="competency.visible"
            class="change-visibility-icon"
            [icon]="['fas', 'eye-slash']"
            (click)="changeCompetencyVisibility(competency)"
          ></fa-icon>
        </div>
      </div>
      <div class="competency-container">
        <div
          class="competency-category-container d-flex flex-row justify-content-between"
        >
          <div>
            <label class="competency-category-title">{{
              "profile_export.profile_modify_modal.competency_language"
                | translate
            }}</label>
            <span
              ngbTooltip="{{
                'profile_export.profile_modify_modal.language_level_tooltip'
                  | translate
              }}"
              placement="bottom"
              container="body"
              class="ms-1"
              ><fa-icon [icon]="['fas', 'circle-info']"></fa-icon
            ></span>
          </div>
          <select
            *ngIf="getLanguageCompetencies().length"
            [(ngModel)]="getLanguageCompetencies()[0].showCompetencyAs"
            (change)="changeLanguageCompetencyDisplayMode($event)"
          >
            <option
              *ngFor="let displayMode of competencyDisplayModes"
              [value]="displayMode"
            >
              {{
                "profile_export.profile_modify_modal.language_competency_display_mode" +
                  "." +
                  displayMode | translate
              }}
            </option>
          </select>
        </div>
        <div
          class="competency-item d-flex flex-row"
          *ngFor="let competency of getLanguageCompetencies()"
          [ngClass]="!competency.visible ? 'not-visible' : ''"
        >
          <label>{{ competency.level }}</label>
          <label>{{ competency.competency?.name }}</label>
          <fa-icon
            *ngIf="!competency.visible"
            class="change-visibility-icon"
            [icon]="['fas', 'eye']"
            (click)="changeCompetencyVisibility(competency)"
          ></fa-icon>
          <fa-icon
            *ngIf="competency.visible"
            class="change-visibility-icon"
            [icon]="['fas', 'eye-slash']"
            (click)="changeCompetencyVisibility(competency)"
          ></fa-icon>
        </div>
      </div>
    </div>
    <div class="studies">
      <div
        class="study-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">{{
          "profile_export.profile_modify_modal.studies_title" | translate
        }}</label>
      </div>
      <div
        *ngFor="let education of profile.education"
        [ngClass]="!education.visible ? 'not-visible' : ''"
        class="study-item d-flex flex-row justify-content-between"
      >
        <label
          >{{ education.educationDTO?.code }}
          {{ education.educationDTO?.name }}
          <label *ngIf="isEducationExpired(education.educationDTO!)">{{
            "profile_export.profile_modify_modal.expired" | translate
          }}</label>
        </label>
        <fa-icon
          *ngIf="!education.visible"
          class="change-visibility-icon"
          [icon]="['fas', 'eye']"
          (click)="changeEducationVisibility(education)"
        ></fa-icon>
        <fa-icon
          *ngIf="education.visible"
          class="change-visibility-icon"
          [icon]="['fas', 'eye-slash']"
          (click)="changeEducationVisibility(education)"
        ></fa-icon>
      </div>
    </div>
  </div>

  <div class="actions d-flex flex-row justify-content-center">
    <button
      type="button"
      class="btn btn-primary cancel-button"
      (click)="activeModal.close(false)"
    >
      {{ "profile_export.profile_modify_modal.cancel" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary ok-button"
      [disabled]="editExternalProjectMode"
      (click)="saveProfile()"
    >
      {{ "profile_export.profile_modify_modal.save" | translate }}
    </button>
  </div>
</div>
