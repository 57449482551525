import { Injectable } from "@angular/core";
import { ConfigurationService } from "./configuration.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({ providedIn: "root" })
export class PermissionService {
  private jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private configService: ConfigurationService) {}

  public checkAdmin(): boolean {
    const accessToken = this.configService.fetchToken("accessToken");
    return this.jwtHelper.decodeToken(accessToken).admin;
  }

  public checkNotSalesUser(): boolean {
    const accessToken = this.configService.fetchToken("accessToken");
    return !this.jwtHelper.decodeToken(accessToken).sales;
  }

  public checkGlobal(): boolean {
    const accessToken = this.configService.fetchToken("accessToken");
    return this.jwtHelper.decodeToken(accessToken).global;
  }
}
