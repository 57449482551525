import { Component, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  CompanyDTO,
  CompetencyCondition,
  CompetencyDTO,
  CompetencyService,
  EmployeeSearchDTO,
  SmartSearchService,
} from "src/shared/api/generated";
import { TableSmartSearchResultComponent } from "./table-smart-search-result/table-smart-search-result.component";
import { PermissionService } from "src/shared/services/permission.service";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-smart-search",
  templateUrl: "./smart-search.component.html",
  styleUrls: ["./smart-search.component.scss"],
})
export class SmartSearchComponent implements OnInit {
  @ViewChild("exactMatchesTable")
  exactMatchesTable!: TableSmartSearchResultComponent;
  @ViewChild("approxMatchesTable")
  approxMatchesTable!: TableSmartSearchResultComponent;
  headers: CompetencyDTO[] = [];
  competencies: CompetencyDTO[] = [];
  competencyConditions: CompetencyCondition[] = [];
  selectedCompanies: CompanyDTO[] = [];
  heatmapConditions: CompetencyCondition[] = [];
  exactMatches: EmployeeSearchDTO[] = [];
  approxMatches: EmployeeSearchDTO[] = [];
  exactPage = 1;
  EXACT_PAGE_SIZE = 10;
  exactCollectionSize = 0;
  approxPage = 1;
  APPROX_PAGE_SIZE = 10;
  approxCollectionSize = 0;

  selectedExactEmployeeIds: number[] = [];
  selectedApproxEmployeeIds: number[] = [];
  allSelectedEmployeeIds: number[] = [];

  isNotSales: boolean = true;

  constructor(
    private competencyService: CompetencyService,
    private smartSearchService: SmartSearchService,
    private permissionService: PermissionService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.fetchCompetencies().pipe(showLoadingSpinner(this.spinnerService)).subscribe((allCompetencies) => {
      this.competencies = allCompetencies;
    });
    this.isNotSales = this.permissionService.checkNotSalesUser();
  }

  fetchCompetencies() {
    return this.competencyService.getAllCompetencies();
  }

  assembleCompetencyHeaders(competencyConditions: CompetencyCondition[]) {
    this.competencies.forEach((competency) => {
      if (
        [...competencyConditions]
          .map((competencyCondition) => competencyCondition.competencyId)
          .includes(competency.id!)
      ) {
        this.headers.push(competency);
      }
    });
  }

  assembleTable(competencyConditions: CompetencyCondition[]) {
    this.headers = [];
    this.assembleCompetencyHeaders(competencyConditions);
    this.competencyConditions = competencyConditions;
    this.fetchSearchResults();
  }

  onCompanySelectionChanged(companies: CompanyDTO[]) {
    this.selectedCompanies = companies;
  }

  fetchSearchResults() {
    this.smartSearchService
      .getSearchResults(
        {
          companies: Array.from(this.selectedCompanies),
          conditions: Array.from(this.competencyConditions),
        },
        this.exactPage - 1,
        this.EXACT_PAGE_SIZE,
        this.approxPage - 1,
        this.APPROX_PAGE_SIZE
      ).pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((results) => {
        this.exactMatches = results.exactMatches?.content ?? [];
        this.exactCollectionSize = results.exactMatches?.totalElements ?? 0;
        this.approxMatches = results.approxMatches?.content ?? [];
        this.approxCollectionSize = results.approxMatches?.totalElements ?? 0;
        this.heatmapConditions = [...this.competencyConditions];
        this.exactMatchesTable.emptyEmployeeSelection();
        this.approxMatchesTable.emptyEmployeeSelection();
      });
  }

  refreshExact(page: number) {
    this.exactPage = page;
    this.fetchSearchResults();
  }

  refreshApprox(page: number) {
    this.approxPage = page;
    this.fetchSearchResults();
  }

  exactEmployeeSelectionChanged(employeeIds: number[]) {
    this.selectedExactEmployeeIds = employeeIds;
    this.allSelectedEmployeeIds = [
      ...this.selectedExactEmployeeIds,
      ...this.selectedApproxEmployeeIds,
    ];
  }

  approxEmployeeSelectionChanged(employeeIds: number[]) {
    this.selectedApproxEmployeeIds = employeeIds;
    this.allSelectedEmployeeIds = [
      ...this.selectedExactEmployeeIds,
      ...this.selectedApproxEmployeeIds,
    ];
  }
}
