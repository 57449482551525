<div class="modal-header">
  <h4 *ngIf="createMode" class="modal-title">
    {{ "competency.competency_add_modal.create_competency" | translate }}
  </h4>
  <h4 *ngIf="!createMode" class="modal-title">
    {{ "competency.competency_add_modal.update_competency" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body main-container">
  <div class="form-row">
    <label>{{
      "competency.competency_add_modal.competency_name" | translate
    }}</label>
    <input type="text" [(ngModel)]="competency.name" />
  </div>
  <div class="form-row">
    <label>{{
      "competency.competency_add_modal.competency_type" | translate
    }}</label>
    <select [(ngModel)]="competency.competencyGroupId" class="px-1">
      <option *ngFor="let type of competencyGroups" value="{{ type.id }}">
        {{ type.name }}
      </option>
    </select>
  </div>
</div>
<div *ngIf="error" class="error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">{{
    errorMessage
  }}</ngb-alert>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary cancel-button"
    (click)="activeModal.close(false)"
  >
    {{ "competency.competency_add_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary ok-button"
    (click)="saveCompetency()"
    [disabled]="isSaveDisabled()"
  >
    {{ "competency.competency_add_modal.save" | translate }}
  </button>
</div>
