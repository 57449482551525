import { Component, ViewChild } from "@angular/core";
import { Observable, Subscription, forkJoin, map, switchMap, tap } from "rxjs";
import { NgbAccordionDirective, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CompetencyService,
  CompetencyDTO,
  EmployeeCompetencyService,
  CompetencyGroupDTO,
  CompetencyGroupService,
} from "src/shared/api/generated";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { DisplayAndEditEmployeeCompetencyDto } from "src/shared/models/display-and-edit-employee-competency.model";
import { NgxSpinnerService } from "ngx-spinner";
import { AddOrEditCompetencyModalComponent } from "../add-or-edit-competency-modal/add-or-edit-competency-modal.component";
import { PermissionService } from "src/shared/services/permission.service";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";

@Component({
  selector: "app-employee-competency",
  templateUrl: "./employee-competency.component.html",
  styleUrls: ["./employee-competency.component.scss"],
})
export class EmployeeCompetencyComponent {
  @ViewChild("basicCompetencyAccordion")
  basicCompetencyAccordion!: NgbAccordionDirective;
  @ViewChild("employeeCompetencyAccordion")
  employeeCompetencyAccordion!: NgbAccordionDirective;
  @ViewChild("nonEmployeeCompetencyAccordion")
  nonEmployeeCompetencyAccordion!: NgbAccordionDirective;
  searchText: string = "";
  filteredCompetencies: CompetencyDTO[] = [];
  errorFetchingCompetencies: boolean = false;
  editEmployeeCompetenciesMode: boolean = false;
  editedEmployeeCompetency: DisplayAndEditEmployeeCompetencyDto =
    {} as DisplayAndEditEmployeeCompetencyDto;
  levelEditedEmployeeCompetency: { id: number; level: number } | null = null;
  newEmployeeCompetencyEditMode: boolean = false;
  competencyGroups: CompetencyGroupDTO[] = [];
  selectableCompetencyGroups: CompetencyGroupDTO[] = [];
  isAdmin: boolean = false;

  public competencies: CompetencyDTO[] = [];
  public employeeCompetencies: CompetencyDTO[] = [];
  public filteredEmployeeCompetencies: CompetencyDTO[] = [];
  public nonEmployeeCompetencies: CompetencyDTO[] = [];
  public filteredNonEmployeeCompetencies: CompetencyDTO[] = [];

  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyService: CompetencyService,
    private modalService: NgbModal,
    private competencyManagerService: CompetencyManagerService,
    private competencyGroupService: CompetencyGroupService,
    private employeeCompetencyService: EmployeeCompetencyService,
    private spinnerService: NgxSpinnerService,
    private confirmationModalService: ConfirmationModalService,
    public permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.initialLoad();
    this.isAdmin = this.permissionService.checkAdmin();
    this.subscriptions.add(
      this.competencyManagerService.employeeCompetency.subscribe(
        (data: DisplayAndEditEmployeeCompetencyDto) => {
          if (this.editedEmployeeCompetency.employeeId !== data.employeeId) {
            this.clearEditing();
          }
          this.editedEmployeeCompetency = data;
          if (data.employeeId) {
            this.filterCompetencyArrays(this.competencies);
            this.editEmployeeCompetenciesMode = true;
          } else {
            this.editEmployeeCompetenciesMode = false;
            this.clearEditing();
          }
        }
      )
    );
  }

  initialLoad() {
    forkJoin([
      this.getRootCompetencyGroups(),
      this.getSelectableCompetencyGroups(),
      this.competencyService.getAllCompetencies(),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: ([rootGroups, selectableGroups, competencies]) => {
          this.competencyGroups = rootGroups;
          this.selectableCompetencyGroups = selectableGroups;
          this.filterCompetencyArrays(competencies);
        },
        error: () => (this.errorFetchingCompetencies = true),
      });
  }

  getRootCompetencyGroups() {
    return this.competencyGroupService.getAllRootCompetencyGroups();
  }

  getSelectableCompetencyGroups() {
    return this.competencyGroupService.getAllSelectableCompetencyGroups();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSearchInput() {
    const searchTerm = this.searchText.trim().toLowerCase();
    if (searchTerm === "") {
      this.filteredCompetencies = this.competencies;
      this.filteredEmployeeCompetencies = this.employeeCompetencies;
      this.filteredNonEmployeeCompetencies = this.nonEmployeeCompetencies;
    } else {
      this.filteredCompetencies = this.competencies.filter((competency) =>
        competency.name.toLowerCase().includes(searchTerm)
      );
      this.filteredEmployeeCompetencies = this.employeeCompetencies.filter(
        (competency) => competency.name.toLowerCase().includes(searchTerm)
      );
      this.filteredNonEmployeeCompetencies =
        this.nonEmployeeCompetencies.filter((competency) =>
          competency.name.toLowerCase().includes(searchTerm)
        );

      this.expandCategoriesWithSearchResult();
    }
  }

  collapseAllCategories() {
    if (this.editEmployeeCompetenciesMode) {
      this.employeeCompetencyAccordion.collapseAll();
      this.nonEmployeeCompetencyAccordion.collapseAll();
    } else {
      this.basicCompetencyAccordion.collapseAll();
    }
  }

  expandCategoriesWithSearchResult() {
    for (let type of this.competencyGroups) {
      if (!this.editEmployeeCompetenciesMode) {
        this.expandBasicCompetencyCategories(type);
      } else if (this.editedEmployeeCompetency.displayOnly) {
        this.expandDisplayOnlyCompetencyCategories(type);
      } else {
        this.expandEmployeeAndNonEmployeeCompetencyCategories(type);
      }
    }
  }

  private expandEmployeeAndNonEmployeeCompetencyCategories(
    type: CompetencyGroupDTO
  ) {
    this.expandCompetencyCategoriesWithSearchResult(
      type,
      this.getFilteredCompetencies(),
      this.employeeCompetencyAccordion
    );
    this.expandCompetencyCategoriesWithSearchResult(
      type,
      this.filteredNonEmployeeCompetencies,
      this.nonEmployeeCompetencyAccordion
    );
  }

  private expandDisplayOnlyCompetencyCategories(type: CompetencyGroupDTO) {
    this.expandCompetencyCategoriesWithSearchResult(
      type,
      this.getFilteredCompetencies(),
      this.employeeCompetencyAccordion
    );
  }

  private expandBasicCompetencyCategories(type: CompetencyGroupDTO) {
    this.expandCompetencyCategoriesWithSearchResult(
      type,
      this.filteredCompetencies,
      this.basicCompetencyAccordion
    );
  }

  expandCompetencyCategoriesWithSearchResult(
    type: CompetencyGroupDTO,
    competencies: CompetencyDTO[],
    accordion: NgbAccordionDirective
  ) {
    const filteredCompetenciesNumber = this.getCompetenciesInCategoryFromArray(
      competencies,
      type.id
    ).length;
    if (filteredCompetenciesNumber !== 0) {
      accordion.expand(type.name);
    } else {
      accordion.collapse(type.name);
    }
  }

  onAddCompetency(competencyName: string) {
    if (this.editedEmployeeCompetency.displayOnly || !this.isAdmin) {
      return;
    }
    const modalRef = this.modalService.open(AddOrEditCompetencyModalComponent);
    modalRef.componentInstance.competency = {
      id: undefined,
      name: competencyName,
      competencyGroupId: this.selectableCompetencyGroups[0].id,
    };
    modalRef.componentInstance.competencyGroups =
      this.selectableCompetencyGroups;
    modalRef.componentInstance.createMode = true;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.searchText = result.name;
          this.getCompetencies();
        }
      },
      () => {}
    );
  }

  onEditCompetency(competency: CompetencyDTO) {
    if (this.editedEmployeeCompetency.displayOnly || !this.isAdmin) {
      return;
    }
    this.hasAssignments(competency.id!).subscribe((hasAssignments) => {
      if (!hasAssignments) {
        this.editCompetency(competency);
      } else {
        this.confirmationModalService
          .openConfirmationModal(
            "competency.confirm_update",
            "competency.update_dialog_message"
          )
          .then(
            (result) => {
              if (result) {
                this.editCompetency(competency);
              }
            },
            () => {}
          );
      }
    });
  }

  editCompetency(competency: CompetencyDTO) {
    const modalRef = this.modalService.open(AddOrEditCompetencyModalComponent);
    modalRef.componentInstance.competency = {
      id: competency.id,
      name: competency.name,
      competencyGroupId: competency.competencyGroup.id,
    };
    modalRef.componentInstance.competencyGroups =
      this.selectableCompetencyGroups;
    modalRef.componentInstance.createMode = false;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.getCompetencies();
        }
      },
      () => {}
    );
  }

  onDeleteCompetency(competencyId: number) {
    if (
      this.editedEmployeeCompetency.displayOnly ||
      this.levelEditedEmployeeCompetency ||
      !this.isAdmin
    ) {
      return;
    }
    this.hasAssignments(competencyId).subscribe((hasAssignments) => {
      if (!hasAssignments) {
        this.deleteCompetency(competencyId);
      } else {
        this.confirmationModalService.openConfirmationModal(
          "competency.delete_error",
          "competency.delete_dialog_message",
          true
        );
      }
    });
  }

  private hasAssignments(competencyId: number): Observable<boolean> {
    return this.competencyService
      .getNumberOfUsagesOfCompetency(competencyId)
      .pipe(map((numberOfAssignments: number) => numberOfAssignments > 0));
  }

  deleteCompetency(competencyId: number) {
    this.competencyService
      .deleteCompetency(competencyId)
      .pipe(
        switchMap(() =>
          this.competencyService
            .getAllCompetencies()
            .pipe(showLoadingSpinner(this.spinnerService))
        ),
        tap((result) => this.filterCompetencyArrays(result))
      )
      .subscribe({
        next: () => {
          this.refreshEditedEmployeeCompetency();
          this.competencyManagerService.setCompetencyDeleted();
        },
        error: (error) => {
          if (
            error.error.message ===
            "Competency cannot be deleted because it's already in use."
          ) {
            this.confirmationModalService.openConfirmationModal(
              "competency.delete_error",
              "competency.delete_dialog_message",
              true
            );
          }
        },
      });
  }

  isAddButtonDisabled() {
    return (
      this.filteredCompetencies.filter(
        (competency) =>
          competency.name.toLowerCase() === this.searchText.trim().toLowerCase()
      ).length > 0 || this.searchText.trim().toLowerCase() === ""
    );
  }

  public getCompetencies(): void {
    this.competencyService
      .getAllCompetencies()
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (response) => {
          this.filterCompetencyArrays(response);
        },
        error: () => {
          this.errorFetchingCompetencies = true;
        },
      });
  }

  filterCompetencyArrays(competencyArray: CompetencyDTO[]) {
    this.competencies = [...competencyArray].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    if (this.editedEmployeeCompetency.employeeId) {
      this.employeeCompetencies = this.competencies.filter((c) =>
        this.editedEmployeeCompetency.competencyList
          .map((obj) => obj.id)
          .includes(c.id!)
      );
      this.nonEmployeeCompetencies = this.competencies.filter(
        (c) =>
          !this.editedEmployeeCompetency.competencyList
            .map((obj) => obj.id)
            .includes(c.id!)
      );

      this.filteredEmployeeCompetencies = this.employeeCompetencies;
      this.filteredNonEmployeeCompetencies = this.nonEmployeeCompetencies;
    }
    this.onSearchInput();
    this.errorFetchingCompetencies = false;
  }

  getFilteredCompetencies(): CompetencyDTO[] {
    if (this.editedEmployeeCompetency.employeeId) {
      return this.filteredEmployeeCompetencies;
    } else {
      return [];
    }
  }

  deleteEditedCompetency(competency: CompetencyDTO) {
    if (this.editedEmployeeCompetency.employeeId) {
      this.deleteEmployeeCompetency(competency);
    }
  }

  deleteEmployeeCompetency(competency: CompetencyDTO) {
    if (
      this.editedEmployeeCompetency.displayOnly ||
      this.levelEditedEmployeeCompetency
    ) {
      return;
    }
    this.employeeCompetencyService
      .deleteEmployeeCompetency({
        employeeId: this.editedEmployeeCompetency.employeeId!,
        competencyId: competency.id!,
        level: this.editedEmployeeCompetency.competencyList.find(
          (comp) => comp.id === competency.id!
        )?.level!,
      })
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (_) => {
          this.refreshEmployeeCompetenciesAfterDelete(competency);
          this.competencyManagerService.setEmployeeCompetencyUpdated();
        },
        error: (error) => {
          if (
            error.error.message ===
            "Unable to remove competency from employee because this competency is connected to at least one of the employee's plans"
          ) {
            this.confirmationModalService.openConfirmationModal(
              "employee.error.title",
              "employee.error.competency_already_planned",
              true
            );
          }
        },
      });
  }

  refreshEmployeeCompetenciesAfterDelete(competency: CompetencyDTO) {
    this.removeFromFilteredEmployeeCompetencies(competency);
    this.addToFilteredNonEmployeeCompetencies(competency);
    this.employeeCompetencies = this.filteredEmployeeCompetencies;
    this.editedEmployeeCompetency.competencyList =
      this.editedEmployeeCompetency.competencyList.filter(
        (c) => c.id !== competency.id
      );
    this.refreshEditedEmployeeCompetency();
  }

  private addToFilteredNonEmployeeCompetencies(competency: CompetencyDTO) {
    this.filteredNonEmployeeCompetencies = [
      competency,
      ...this.filteredNonEmployeeCompetencies,
    ].sort((a, b) => a.name.localeCompare(b.name));
  }

  private removeFromFilteredEmployeeCompetencies(competency: CompetencyDTO) {
    this.filteredEmployeeCompetencies = this.filteredEmployeeCompetencies
      .filter((c) => c.id !== competency.id!)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  createEditedCompetency(competency: CompetencyDTO) {
    if (
      this.editedEmployeeCompetency.employeeId &&
      !this.levelEditedEmployeeCompetency
    ) {
      this.createEmployeeCompetency(competency);
    }
  }

  createEmployeeCompetency(competency: CompetencyDTO) {
    if (this.editedEmployeeCompetency.displayOnly) {
      return;
    }
    this.levelEditedEmployeeCompetency = { id: competency.id!, level: 5 };
    this.removeFromFilteredNonEmployeeCompetencies(competency);
    this.addToFilteredEmployeeCompetencies(competency);
    this.employeeCompetencies = this.filteredEmployeeCompetencies;
    this.newEmployeeCompetencyEditMode = true;
    this.employeeCompetencyAccordion.expand(
      this.getRootGroupName(competency.competencyGroup.rootGroupId!)
    );
  }

  getRootGroupName(id: number) {
    return this.competencyGroups.find((c) => c.id === id)!.name;
  }

  onStartEditingLevel(competency: CompetencyDTO) {
    this.levelEditedEmployeeCompetency = {
      id: competency.id!,
      level: this.getCompetencyLevel(competency.id!)!,
    };
  }

  onSaveLevelEditing() {
    if (this.newEmployeeCompetencyEditMode) {
      this.saveNewEmployeeCompetency();
    } else {
      this.saveEditedEmployeeCompetency();
    }
  }

  saveNewEmployeeCompetency() {
    this.employeeCompetencyService
      .createEmployeeCompetency({
        employeeId: this.editedEmployeeCompetency.employeeId!,
        competencyId: this.levelEditedEmployeeCompetency!.id,
        level: this.levelEditedEmployeeCompetency!.level,
      })
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (createdCompetency) => {
          this.editedEmployeeCompetency.competencyList.push({
            id: createdCompetency.competencyId,
            level: createdCompetency.level,
          });
          this.refreshEditedEmployeeCompetency();
          this.levelEditedEmployeeCompetency = null;
          this.newEmployeeCompetencyEditMode = false;
          this.competencyManagerService.setEmployeeCompetencyUpdated();
        },
        error: (error) => {
          this.openLevelEditingErrorModal(error);
        },
      });
  }

  openLevelEditingErrorModal(error: any) {
    if (error.error.message === "Level cannot be less than 1") {
      this.confirmationModalService.openConfirmationModal(
        "employee.error.title",
        "employee.error.competency_less_than_1",
        true
      );
    } else if (error.error.message === "Level cannot be more than 5") {
      this.confirmationModalService.openConfirmationModal(
        "employee.error.title",
        "employee.error.competency_more_than_5",
        true
      );
    }
  }

  saveEditedEmployeeCompetency() {
    this.employeeCompetencyService
      .updateEmployeeCompetency({
        employeeId: this.editedEmployeeCompetency.employeeId!,
        competencyId: this.levelEditedEmployeeCompetency!.id,
        level: this.levelEditedEmployeeCompetency!.level,
      })
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (updatedEmployeeCompetency) => {
          this.editedEmployeeCompetency.competencyList.find(
            (item) => item.id === updatedEmployeeCompetency.competencyId
          )!.level = updatedEmployeeCompetency.level;
          this.refreshEditedEmployeeCompetency();
          this.levelEditedEmployeeCompetency = null;
          this.competencyManagerService.setEmployeeCompetencyUpdated();
        },
        error: (error) => {
          this.openLevelEditingErrorModal(error);
        },
      });
  }

  onCancelLevelEditing() {
    if (this.newEmployeeCompetencyEditMode) {
      const cancelledCompetency = this.competencies.find(
        (c) => c.id === this.levelEditedEmployeeCompetency?.id
      )!;
      this.removeFromFilteredEmployeeCompetencies(cancelledCompetency);
      this.addToFilteredNonEmployeeCompetencies(cancelledCompetency);
    }
    this.levelEditedEmployeeCompetency = null;
    this.newEmployeeCompetencyEditMode = false;
  }

  private addToFilteredEmployeeCompetencies(competency: CompetencyDTO) {
    this.filteredEmployeeCompetencies = [
      competency,
      ...this.filteredEmployeeCompetencies,
    ].sort((a, b) => a.name.localeCompare(b.name));
  }

  private removeFromFilteredNonEmployeeCompetencies(competency: CompetencyDTO) {
    this.filteredNonEmployeeCompetencies = this.filteredNonEmployeeCompetencies
      .filter((c) => c.id !== competency.id!)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  refreshEditedEmployeeCompetency() {
    this.competencyManagerService.updateEmployeeCompetency(
      this.editedEmployeeCompetency.displayOnly,
      this.editedEmployeeCompetency.employeeId!,
      this.editedEmployeeCompetency.employeeName!,
      this.editedEmployeeCompetency.competencyList
    );
  }

  getCompetencyLevel(id: number) {
    if (!this.editedEmployeeCompetency.employeeId) {
      return -1;
    }
    return this.editedEmployeeCompetency.competencyList.find((c) => c.id === id)
      ?.level;
  }

  clearEditing() {
    this.onCancelLevelEditing();
  }

  getCompetenciesInCategoryFromArray(
    array: CompetencyDTO[],
    typeId: number
  ): CompetencyDTO[] {
    return array
      .filter((c) => c.competencyGroup.rootGroupId === typeId)
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}
