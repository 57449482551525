import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  CompanyDTO,
  CompetencyDTO,
  CompetencyService,
  ProjectService,
  ProjectTableDTO,
} from "src/shared/api/generated";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/shared/directives/ngbd-sortable-header.directive";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { FormControl } from "@angular/forms";
import {
  Subscription,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  switchMap,
  tap,
} from "rxjs";
import { PermissionService } from "src/shared/services/permission.service";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-table-project",
  templateUrl: "./table-project.component.html",
  styleUrls: ["./table-project.component.scss"],
})
export class TableProjectComponent implements OnInit, OnDestroy {
  @Input() company: CompanyDTO;
  isAdmin: boolean = false;
  projects: ProjectTableDTO[] = [];
  page = 1;
  pageSize = 15;
  collectionSize = 0;
  sorting: SortEvent = { column: "name", direction: "asc" };
  searchText = new FormControl("");
  selectedRows: number[] = [];
  search: boolean = false;
  competencies: Map<number, string> = new Map();
  displayedProjects: string = "all";
  contextMenuOpenedFor: number = -1;

  timeoutId: any = null;

  createProjectMode: boolean = false;
  cloneProjectMode: boolean = false;
  projectIdToClone: number = -1;
  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyService: CompetencyService,
    private projectService: ProjectService,
    private spinnerService: NgxSpinnerService,
    private competencyManagerService: CompetencyManagerService,
    private permissionService: PermissionService,
    private confirmationModalService: ConfirmationModalService
  ) {
    this.company = {} as CompanyDTO;
  }

  ngOnInit(): void {
    this.isAdmin = this.permissionService.checkAdmin();
    this.initialLoad();
    this.subscriptions.add(
      this.competencyManagerService.competencyUpdated.subscribe((_) => {
        this.getAllCompetencies();
      })
    );
    this.subscriptions.add(
      this.competencyManagerService.competencyDeleted.subscribe((_) => {
        this.refreshProjectsAndCompetencies();
      })
    );
    this.searchProjects();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.cancelCloneProject();
  }

  getProjectsForCurrentCompany(searchTerm: string = "") {
    if (this.cloneProjectMode) {
      this.cancelCloneProject();
    }
    if (this.createProjectMode) {
      this.cancelCreateProject();
    }

    if (this.displayedProjects === "active") {
      return this.projectService.getActiveProjectsByCompanyId(
        this.company.id!,
        searchTerm.trim(),
        this.pageSize,
        this.page - 1,
        this.sorting.column,
        this.sorting.direction
      );
    }
    return this.projectService.getAllProjectsByCompanyId(
      this.company.id!,
      searchTerm.trim(),
      this.pageSize,
      this.page - 1,
      this.sorting.column,
      this.sorting.direction
    );
  }

  getAllCompetenciesObservable() {
    return this.competencyService.getAllCompetencies();
  }

  getAllCompetencies() {
    this.getAllCompetenciesObservable().subscribe((result) => {
      result.forEach((competency: CompetencyDTO) => {
        this.competencies.set(competency.id!, competency.name);
      });
    });
  }

  initialLoad() {
    this.refreshProjectsAndCompetencies();
  }

  refreshProjectsAndCompetencies(){
    forkJoin([
      this.getAllCompetenciesObservable(),
      this.getProjectsForCurrentCompany(this.searchText.value ?? ""),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe(([competencies, projects]) => {
        competencies.forEach((competency: CompetencyDTO) => {
          this.competencies.set(competency.id!, competency.name);
        });
        this.projects = projects.content ?? [];
        this.collectionSize = projects.totalElements ?? 0;
        this.selectedRows = [];
        this.contextMenuOpenedFor = -1;
      });
  }

  saveProject(project: ProjectTableDTO) {
    this.projectService.updateProject(project).subscribe({
      next: (_) => {
        this.refreshProjects();
      },
      error: (error) => {
        if (
          error.error.message === "Project already exists with the given name"
        ) {
          this.confirmationModalService.openConfirmationModal(
            "project.error.title",
            "project.error.duplicate_name",
            true
          );
        }
      },
    });
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> =
    new QueryList();

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.sorting = { column: column, direction: direction };
    this.refreshProjects();
  }

  refreshProjects() {
    this.getProjectsForCurrentCompany(this.searchText.value ?? "")
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((projectPage) => {
        this.projects = projectPage.content ?? [];
        this.collectionSize = projectPage.totalElements ?? 0;
        this.selectedRows = [];
        this.contextMenuOpenedFor = -1;
      });
  }

  toggleDisplayMode() {
    this.page = 1;
    this.refreshProjects();
    this.competencyManagerService.clearProjectCompetency();
  }

  searchProjects() {
    this.searchText.valueChanges
      .pipe(
        tap(() => (this.search = false)),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.competencyManagerService.clearProjectCompetency();
        }),
        switchMap((text) =>
          this.getProjectsForCurrentCompany(text ?? "").pipe(
            showLoadingSpinner(this.spinnerService)
          )
        )
      )
      .subscribe((projectPage) => {
        this.projects = projectPage.content ?? [];
        this.collectionSize = projectPage.totalElements ?? 0;
        this.selectedRows = [];
        this.search = true;
      });
  }

  addEmptyProject() {
    this.projects.unshift({
      id: undefined,
      name: "",
      start: "",
      end: "",
      internal: true,
      projectState: "PLANNED",
      fte: 0,
      companyId: this.company.id!,
      competencies: undefined,
      members: [],
      alternativeName: undefined,
      description: undefined,
    });
    this.collectionSize++;
    this.createProjectMode = true;
  }

  createProject() {
    this.projectService
      .createProject(this.projects.find((project) => !project.id)!)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (createdProject) => {
          let index = this.projects.findIndex((project) => !project.id);
          this.projects[index] = { ...createdProject };
          this.createProjectMode = false;
        },
        error: (error) => {
          if (
            error.error.message === "Project already exists with the given name"
          ) {
            this.confirmationModalService.openConfirmationModal(
              "project.error.title",
              "project.error.duplicate_name",
              true
            );
          }
        },
      });
  }

  cancelCreateProject() {
    this.collectionSize--;
    this.createProjectMode = false;
    this.projects = this.projects.filter((project) => project.id);
  }

  isCreateDisabled() {
    let createdProject = this.projects.find((project) => !project.id);
    return (
      createdProject?.name.trim() === "" ||
      createdProject?.start === "" ||
      createdProject?.end === ""
    );
  }

  selectRow(id: number) {
    if (this.createProjectMode) {
      return;
    }
    if (this.selectedRows.includes(id)) {
      this.selectedRows = this.selectedRows.filter((rowId) => rowId !== id);
    } else {
      this.selectedRows.push(id);
    }
  }

  highlightRow(id: number) {
    this.contextMenuOpenedFor = id;
  }

  removeHighlightFromRow() {
    this.contextMenuOpenedFor = -1;
  }

  openDeleteConfirmationModal(id: number) {
    this.confirmationModalService.openConfirmationModal(
      "project.confirm_delete",
      "project.delete_dialog_message"
    ).then(
      (result) => {
        if (result) {
          this.deleteProject(id);
        }
      },
      () => {}
    );
  }

  deleteProject(id: number) {
    this.projectService.deleteProject(id).subscribe({
      next: () => {
        this.refreshProjects();
      },
    });
  }

  cloneProject(id: number) {
    this.projectService.cloneProject(id).subscribe({
      next: (cloneProject) => {
        this.projects.unshift(cloneProject);
        this.collectionSize++;
        this.projectIdToClone = id;
        this.cloneProjectMode = true;
      },
    });
  }

  cancelCloneProject() {
    this.collectionSize--;
    this.projects = this.projects.filter((project) => project.id);
    this.cloneProjectMode = false;
    this.projectIdToClone = -1;
  }

  saveClonedProject() {
    this.projectService
      .saveProjectClone(
        this.projectIdToClone,
        this.projects.find((project) => !project.id)!
      )
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (cloneProject) => {
          let index = this.projects.findIndex((project) => !project.id);
          this.projects[index] = { ...cloneProject };
          this.cloneProjectMode = false;
          this.projectIdToClone = -1;
        },
        error: (error) => {
          if (
            error.error.message === "Project already exists with the given name"
          ) {
            this.confirmationModalService.openConfirmationModal(
              "project.error.title",
              "project.error.duplicate_name",
              true
            );
          }
        },
      });
  }
}
