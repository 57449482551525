import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EducationDTO, EducationService } from "src/shared/api/generated";
import { isDeepEqual } from "src/shared/util/is-deep-equal";

@Component({
  selector: "[app-tr-education]",
  templateUrl: "./tr-education.component.html",
  styleUrls: ["./tr-education.component.scss"],
})
export class TrEducationComponent implements OnInit {
  @Input() education: EducationDTO = {} as EducationDTO;
  @Input() createMode: boolean = false;
  @Output() educationSaved = new EventEmitter<void>();
  @Output() educationSaveFailed = new EventEmitter<string>();

  editedEducation: EducationDTO = {} as EducationDTO;
  editedCell: string = "";
  saved: boolean = false;

  ngOnInit(): void {
    this.resetEditedEducation();
  }

  constructor(private educationService: EducationService){}

  setEditStateTrue(key: string) {
    if (this.createMode) {
      return;
    }

    this.editedCell = key;
    this.saved = false;
  }

  resetEditedEducation() {
    this.editedEducation = JSON.parse(JSON.stringify(this.education));
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }
  
  isEditingDisabled(): boolean {
    if (this.education.id && this.createMode) {
      return true;
    }
    return false;
  }

  setEditStateFalse() {
    if (this.createMode) {
      return;
    }
    this.resetEditedEducation();
    this.editedCell = "";
    this.saved = false;
  }

  saveEducation() {
    if (this.createMode) {
      return;
    }
    if (this.saved) {
      return;
    }
    if (
      isDeepEqual(this.editedEducation, this.education) ||
      this.editedEducation.name.trim() === ""
    ) {
      this.setEditStateFalse();
      return;
    }
    this.saved = true;

    this.educationService.updateEducation(this.editedEducation).subscribe({
      next: (_) => {
        this.educationSaved.emit();
      },
      error: (error) => {
        this.educationSaveFailed.emit(error.error.message);
      },
    });
    this.setEditStateFalse();
  }

  deleteEducation(){
    if(!this.education.id || this.createMode){
      return;
    }
    this.educationService.deleteEducation(this.education.id).subscribe({
      next: () => {
        this.educationSaved.emit();
      }
    });
  }
}
